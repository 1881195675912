import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import * as PropTypes from 'prop-types';

import { Loading } from 'components';
import { redirects, getRoutes } from './navigation';
import { useFetchBasket } from './hooks';

const renderRoute = ({ path, page }) => <Route path={path} key={path} component={page} />;

renderRoute.propTypes = {
  path: PropTypes.string.isRequired,
  page: PropTypes.element.isRequired,
};

const renderRedirect = ({ from, to, exact }) => <Redirect from={from} to={to} key={`${from}=>${to}`} exact={exact} />;

renderRedirect.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

renderRedirect.defaultProps = {
  from: undefined,
  exact: true,
};

export const App = () => {
  const { data: { status } = {}, isLoading } = useFetchBasket();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      {getRoutes(status).map(renderRoute)}
      {redirects.map(renderRedirect)}
    </Switch>
  );
};
