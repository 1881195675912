import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { IconSelected } from 'components';

export const PlanSummaryCard = ({
  plan, date, isSelected, showDateCard,
}) => (
  <Card isSelected={isSelected}>
    <DateCardBackground url={plan && plan.themeImage}>
      {showDateCard && Boolean(plan) && (
        <DateCard>
          <div>{date.toLocaleString('en', { month: 'short' }).toUpperCase()}</div>
          <div>{date.toLocaleString('en', { day: 'numeric' })}</div>
        </DateCard>
      )}
      {!plan && (
        <FreeDayThumbnail>
          <Trans i18nKey="planChange.planSummaryCard.freeDayThumbnail" />
        </FreeDayThumbnail>
      )}
    </DateCardBackground>
    <ActivePlanContent>
      <ActivePlanTitle>
        {plan ? plan.themeOfTheDay : <Trans i18nKey="planChange.planSummaryCard.freeDayTitle" />}
      </ActivePlanTitle>
      {Boolean(plan) && <DesignerImage src={plan && plan.tridDesigner.image} alt="designer" />}
      <DesignerName>
        {plan ? (
          plan.tridDesigner.fullName
        ) : (
          <Trans
            values={{ date: date.toLocaleString('en', { month: 'long', day: 'numeric' }) }}
            i18nKey="planChange.planSummaryCard.freeDayDescription" />
        )}
      </DesignerName>
    </ActivePlanContent>
    {isSelected && <Selected />}
  </Card>
);

PlanSummaryCard.propTypes = {
  plan: PropTypes.shape({
    themeImage: PropTypes.string,
    shortDescription: PropTypes.string,
    themeOfTheDay: PropTypes.string,
    tridDesigner: PropTypes.shape({
      image: PropTypes.string,
      fullName: PropTypes.string,
    }),
  }),
  date: PropTypes.instanceOf(Date).isRequired,
  isSelected: PropTypes.bool,
  showDateCard: PropTypes.bool,
};

PlanSummaryCard.defaultProps = {
  plan: undefined,
  isSelected: false,
  showDateCard: false,
};

const Card = styled.div`
  & {
    border-radius: 4px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    overflow: hidden;
    display: flex;
    border: solid 1px ${({ isSelected }) => (isSelected ? 'var(--green)' : 'var(--red-pink)')};
  }
`;

const DateCard = styled.div`
  & {
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 4px;
    background: transparent;
    backdrop-filter: blur(14px);

    > div {
      font-size: 15px;
      color: var(--white);
    }

    > div:first-child {
      font-family: 'Airbnb Cereal App Book' !important;
      font-size: 13px;
      color: var(--white);
    }
  }
`;

const DateCardBackground = styled.div`
  & {
    width: 44px;
    height: 44px;
    padding: 10px;
    ${({ url }) => (url ? `background: url(${url})` : 'background-color: var(--seafoam-blue)')};
    background-size: cover;
  }
`;

const ActivePlanContent = styled.div`
  & {
    flex: 1;
    padding: 9px 12px;
    display: grid;
    grid-template-areas:
      'title title'
      'image name';
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
  }
`;

const ActivePlanTitle = styled.div`
  & {
    font-size: 12px;
    grid-area: title;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--slate);
  }
`;

const DesignerImage = styled.img`
  & {
    grid-area: image;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const DesignerName = styled.div`
  & {
    grid-area: name;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const Selected = styled(IconSelected)`
  & {
    margin: -1px;
  }
`;

const FreeDayThumbnail = styled.div`
  & {
    color: var(--white);
    font-family: 'Airbnb Cereal App Bold' !important;
    text-align: center;
    margin: 0 -10px;
    font-size: 16px;
  }
`;
