import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconQuotation = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16">
    <path
      fill="#1B2D41"
      fillRule="evenodd"
      d="M13.76.5c-.405 0-.776.1-1.13.243l.01-.026-.374.174-.033.013-.017.012-.513.355-.222.223c-.8.773-1.676 2.254-1.443 5.07.137 2.202 1.155 5.5 5.23 8.71a1.055 1.055 0 0 0 1.496-.193c.361-.47.276-1.148-.189-1.513-3.32-2.612-4.227-5.173-4.388-6.929.469.266.999.43 1.573.43 1.788 0 3.24-1.471 3.24-3.285S15.55.5 13.76.5zM3.766.5c-.404 0-.778.1-1.132.243l.011-.026-.373.174-.036.013-.017.012-.51.355-.224.223C.68 2.267-.195 3.748.038 6.564c.139 2.202 1.154 5.5 5.23 8.71a1.054 1.054 0 0 0 1.495-.193c.36-.47.277-1.148-.19-1.513C3.255 10.956 2.35 8.395 2.19 6.64c.469.266.998.43 1.572.43C5.554 7.068 7 5.597 7 3.783S5.554.5 3.766.5z" />
  </svg>
);

IconQuotation.propTypes = {
  className: PropTypes.string,
};

IconQuotation.defaultProps = {
  className: undefined,
};
