import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconChange = ({ className, colorful }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="26"
    viewBox="0 0 30 26">
    <g fill="none" fillRule="evenodd">
      <path
        fill={colorful ? '#1EAB31' : '#1B2D41'}
        d="M23.887 24.318V4.838l3.648 3.647a1.108 1.108 0 1 0 1.567-1.566L23.563 1.38a1.108 1.108 0 0 0-1.567 0l-5.538 5.539a1.104 1.104 0 0 0 0 1.566 1.108 1.108 0 0 0 1.566 0l3.648-3.647v19.48a1.108 1.108 0 0 0 2.215 0z" />
      <path
        fill={colorful ? '#F53158' : '#1B2D41'}
        d="M13.285 19.302a1.108 1.108 0 0 0-1.566-1.567L8.07 21.383V1.903a1.108 1.108 0 0 0-2.216 0v19.48l-3.647-3.648A1.108 1.108 0 1 0 .64 19.302L6.18 24.84a1.108 1.108 0 0 0 1.566 0l5.54-5.54z" />
    </g>
  </svg>
);

IconChange.propTypes = {
  className: PropTypes.string,
  colorful: PropTypes.bool,
};

IconChange.defaultProps = {
  className: undefined,
  colorful: false,
};
