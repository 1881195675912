import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconWalk = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="24"
    viewBox="0 0 17 24">
    <path
      fill="none"
      fillRule="nonzero"
      stroke="#1B2D41"
      strokeWidth="1.263"
      d="M12.411 10.038zm-.07-.153c.399.074 1.267.23 1.986.359.74.075 1.305.703 1.305 1.456a1.458 1.458 0 0 1-1.702 1.448l-.464-.096-1.023-.212-.007-.001c-.845-.176-1.377-.286-1.474-.308-.339-.075-.64-.334-.827-.62l-.08.398a110135.888 110135.888 0 0 1 .966 1.05l.002.002c.753.819 1.158 1.26 1.196 1.304-.107-.124.13.127.219.27.192.312.285.726.266 1.274-.01.294-.013 1.143-.01 2.359.002.809.006 1.655.01 2.368l-.002.171-.006.07a1.604 1.604 0 0 1-1.587 1.455c-.821 0-1.502-.63-1.596-1.517v-4.182l-1.408-1.44c-.16.986-.25 1.524-.27 1.63-.063.342-.194.593-.496.96l-1.59 1.912v.001l-.923 1.108c-.381.458-.381.458-.57.681a1.58 1.58 0 0 1-1.298.684 1.6 1.6 0 0 1-1.59-1.61c0-.45.184-.87.447-1.114L4.32 16.66l.72-4.19.24-1.161-.318.616a1.464 1.464 0 0 1-1.4 1.053c-.81 0-1.462-.666-1.462-1.48 0-.314.098-.613.274-.863l1.343-2.58c.044-.088.11-.206.2-.33.127-.174.263-.311.443-.403.064-.033.663-.379 1.53-.883l.01-.006 1.073-.625.486-.284.024-.013c.073-.039.183-.087.324-.133.435-.141.895-.176 1.352-.025.603.198 1.062.566 1.443 1.004.127.146.367.631.967 1.89l.026.056c.22.461.592 1.25.747 1.582zM9.239 1.368c1.243 0 2.245 1.025 2.245 2.281 0 1.256-1.002 2.28-2.245 2.28S6.994 4.906 6.994 3.65c0-1.256 1.001-2.28 2.244-2.28z" />
  </svg>
);

IconWalk.propTypes = {
  className: PropTypes.string,
};

IconWalk.defaultProps = {
  className: undefined,
};
