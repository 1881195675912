import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from 'locales';

i18next
  .use(initReactI18next)
  // .use(new ReactPostprocessor())
  .init({
    lng: 'en',
    // postProcess: ['reactPostprocessor'],
    resources,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  });
