import React from 'react';
import * as PropTypes from 'prop-types';

import { PlanChange } from 'containers';

export const PlanChangePage = ({
  match: {
    params: { date },
  },
}) => <PlanChange key={date} />;

PlanChangePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
    }),
  }).isRequired,
};
