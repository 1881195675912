import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, Switch } from 'react-router';

import { App } from './App';
import { Providers } from './Providers';
import { NotFoundPage } from './pages';
import { BasketContextProvider } from './BasketContext';

ReactDOM.render(
  <Providers>
    <Switch>
      {/* eslint-disable-next-line max-len */}
      <Route path="/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})">
        <BasketContextProvider>
          <App />
        </BasketContextProvider>
      </Route>
      <Route path="/not-found">
        <NotFoundPage />
      </Route>
      <Redirect from="/" to="/not-found" />
    </Switch>
  </Providers>,
  document.getElementById('root'),
);
