import React from 'react';
import { Trans } from 'react-i18next';

import { Loading, Main } from 'components';
import { useFetchBasket } from 'hooks';

export const Expired = () => {
  const { data: { name } = {}, isLoading } = useFetchBasket();

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Main
      title={<Trans i18nKey="expired.title" values={{ name }} />}
      text={<Trans i18nKey="expired.text" />}
      link={<Trans i18nKey="expired.link" />}
      useNativeLink
      url="mailto:info@heytripster.com" />
  );
};
