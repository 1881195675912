import React from 'react';
import { Trans } from 'react-i18next';

import { Main } from 'components';

export const Error = () => (
  <Main
    title={<Trans i18nKey="error.title" />}
    text={<Trans i18nKey="error.text" />}
    link={<Trans i18nKey="error.link" />}
    useNativeLink
    url="mailto:info@heytripster.com" />
);
