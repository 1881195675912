import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Loading = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
);

const LoadingWrapper = styled.div`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > div {
      color: var(--dark-blue-grey);
    }
  }
`;
