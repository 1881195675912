import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { get } from 'lodash';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { useParams } from 'react-router';

import {
  ErrorDialog, IconClose, IconSuccess, IconUnlock, Image, Loading,
} from 'components';
import { useFetchBasket, useShare } from 'hooks';

export const Completed = () => {
  const { guid } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: { basketResult = [] } = {}, isLoading } = useFetchBasket();
  const { t } = useTranslation();
  const {
    share, isLoading: isShareLoading, error, isFetched,
  } = useShare();

  const { handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      people: [{ id: 1 }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'people',
  });

  const onSubmit = ({ people }) => {
    share({
      guid,
      body: people,
      onError: () => setIsModalOpen(true),
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageContainer>
          <Layout>
            <Logo alt="logo" fileName="logo" />
            <AlignedSuccessIcon />
            <Title>
              <Trans i18nKey="completed.title" />
            </Title>
            <UnlockCard>
              <IconUnlock />
              <div>
                <UnlockCardTitle>
                  <Trans i18nKey="completed.unlockCard.title" />
                </UnlockCardTitle>
                <UnlockCardText>
                  <Trans i18nKey="completed.unlockCard.text" components={[<a href="mailto:info@heytripster.com">1</a>]} />
                </UnlockCardText>
              </div>
            </UnlockCard>
            <TreeCard>
              <Image alt="hand holding tree" fileName="tree" />
              <TreeText>
                <Trans i18nKey="completed.treeCardText" values={{ count: basketResult.length }} />
              </TreeText>
            </TreeCard>
            {isFetched ? (
              <>
                <SuccessTitle>
                  <Trans i18nKey="completed.successTitle" />
                </SuccessTitle>
                <SuccessText>
                  <Trans i18nKey="completed.successText" />
                </SuccessText>
              </>
            ) : (
              <>
                <FormTitle>
                  <Trans i18nKey="completed.formTitle" />
                </FormTitle>
                <FormFields>
                  {fields.map(({ id }, i) => (
                    <Fragment key={id}>
                      <div />
                      <CloseButton type="button" disabled={fields.length === 1} onClick={() => remove(i)}>
                        <IconClose />
                      </CloseButton>
                      <Controller
                        as={(
                          <TextField
                            label={t('completed.labelName')}
                            error={Boolean(get(errors, `people[${i}].name`))}
                            helperText={get(errors, `people[${i}].name.message`, ' ')}
                            variant="outlined"
                            disabled={isShareLoading} />
                        )}
                        control={control}
                        name={`people[${i}].name`}
                        defaultValue=""
                        rules={{ required: t('completed.required') }} />
                      <Controller
                        as={(
                          <TextField
                            label={t('completed.labelSurname')}
                            error={Boolean(get(errors, `people[${i}].sureName`))}
                            helperText={get(errors, `people[${i}].sureName.message`, ' ')}
                            variant="outlined"
                            disabled={isShareLoading} />
                        )}
                        control={control}
                        name={`people[${i}].sureName`}
                        defaultValue=""
                        rules={{ required: t('completed.required') }} />
                      <Controller
                        as={(
                          <LongTextField
                            label={t('completed.labelEmail')}
                            error={Boolean(get(errors, `people[${i}].email`))}
                            helperText={get(errors, `people[${i}].email.message`, ' ')}
                            variant="outlined"
                            disabled={isShareLoading} />
                        )}
                        control={control}
                        name={`people[${i}].email`}
                        defaultValue=""
                        rules={{ required: t('completed.required') }} />
                      <Divider />
                    </Fragment>
                  ))}
                  <AddButton
                    id="traveller-offer-add-another-button"
                    type="button"
                    onClick={() => append({ id: fields[fields.length - 1].id + 1 })}
                    disabled={isShareLoading}>
                    <Trans i18nKey="completed.add" />
                  </AddButton>
                </FormFields>
                <SubmitButtonWrapper>
                  <SubmitButton type="submit" disabled={isShareLoading} id="traveller-offer-share-my-plans-button">
                    {isShareLoading ? <CircularProgress size={30} /> : <Trans i18nKey="completed.submit" />}
                  </SubmitButton>
                </SubmitButtonWrapper>
              </>
            )}
          </Layout>
        </PageContainer>
      </form>
      <ErrorDialog error={error} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

const PageContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 57px 36px 0 36px;
  }
`;

const Layout = styled.div`
  & {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const AlignedSuccessIcon = styled(IconSuccess)`
  & {
    margin: 40px 0 10px 0;
  }
`;

const Title = styled.div`
  & {
    font-size: 22px;
    line-height: 1.45;
    color: var(--dark-blue-grey);
    text-align: center;
    font-family: 'Airbnb Cereal App Bold' !important;
  }
`;

const UnlockCard = styled.div`
  & {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    column-gap: 16px;
    align-items: center;
    padding: 8px 14px;
    margin: 40px 0;
    border-radius: 4px;
    border: solid 1px var(--green-28);
    background-color: var(--green-9);
    grid-template-columns: max-content 1fr;
  }
`;

const UnlockCardTitle = styled.div`
  & {
    font-size: 14px;
    line-height: 2.29;
    color: var(--dark-blue-grey);
  }
`;

const UnlockCardText = styled.div`
  & {
    font-size: 12px;
    line-height: 2;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;

    > a {
      color: inherit;
      font-family: 'Airbnb Cereal App Book' !important;
    }
  }
`;

const TreeCard = styled.div`
  & {
    width: 100%;
    max-width: 460px;
    display: grid;
    align-items: center;
    padding: 6px 0;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
    grid-template-columns: max-content 1fr;
  }
`;

const TreeText = styled.div`
  & {
    margin: 22px 22px 2px 22px;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
    > strong {
      font-family: 'Airbnb Cereal App Extra Bold' !important;
    }
  }
`;

const FormTitle = styled.div`
  & {
    margin: 60px 0 36px 0;
    font-size: 16px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.44;
    text-align: center;
    color: var(--dark-blue-grey);
  }
`;

const CloseButton = styled.button`
  & {
    justify-self: end;
    width: fit-content;
    border: none;
    background: none;
    cursor: pointer;
    > svg {
      width: 20px;
      height: 20px;
      background-color: var(--regent-gray);
      border-radius: 50%;
      g {
        fill: var(--white);
      }
    }
  }
`;

const FormFields = styled.div`
  & {
    display: grid;
    row-gap: 12px;
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
`;

const LongTextField = styled(TextField)`
  & {
    grid-column: span 2;
  }
`;

const Divider = styled.div`
  & {
    height: 1px;
    background: rgba(0, 0, 0, 0.23);
    grid-column: span 2;
    margin-bottom: 12px;
  }
`;

const AddButton = styled(Button)`
  && {
    grid-column: span 2;
    height: 56px;
    width: 100%;
    background: none;
    border-radius: 4px;
    border: dashed 1px rgba(0, 0, 0, 0.23);
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 24px;

    :hover {
      color: var(--dark-blue-grey);
    }
  }
`;

const SubmitButton = styled(Button)`
  && {
    height: 65px;
    width: 100%;
    background-color: var(--dark-blue-grey);
    border-radius: 0;
    font-size: 15px;
    line-height: 1.2;
    color: var(--white);
    z-index: 1;

    :hover {
      background-color: var(--rhino);
    }

    svg {
      color: var(--white);
    }

    @media ${({ theme }) => theme.device.web} {
      position: relative;
      bottom: initial;
      border-radius: 4px;
    }
  }
`;

const SubmitButtonWrapper = styled.div`
  && {
    position: sticky;
    bottom: 0;
    height: 65px;
    width: 100vw;
    z-index: 10000;

    @media ${({ theme }) => theme.device.web} {
      position: initial;
      bottom: initial;
      width: 100%;
      margin-bottom: 50px;
    }
  }
`;

const SuccessTitle = styled.div`
  & {
    margin: 60px 0 8px 0;
    font-size: 22px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.45;
    color: var(--dark-blue-grey);
  }
`;

const SuccessText = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const Logo = styled(Image)`
  & {
    width: 198px;
    height: 42px;

    @media ${({ theme }) => theme.device.web} {
      width: 228px;
      height: 48px;
    }
  }
`;
