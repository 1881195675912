import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button, CircularProgress, Dialog, useMediaQuery,
} from '@material-ui/core';
import ReactJoyride from 'react-joyride';

import { useFetchBasket, useChangePlan, useTheme } from 'hooks';
import { getPlanUrl } from 'navigation';
import { IconClose, IconChange, Loading } from 'components';
import { storage } from 'adapters';
import { TutorialDialog } from 'components/TutorialDialog';
import step1 from 'assets/tutorial-step5.png';
import step2 from 'assets/tutorial-step6.png';
import step3 from 'assets/tutorial-step7.png';
import {
  PlanSummaryCard, FreeDayCard, PlanCard, PlanPreview,
} from './components';

export const PlanChange = () => {
  const [{ selectedPlanId, previewPlanId, isModalOpen }, setState] = useState({});
  const history = useHistory();
  const { changePlan, isLoading: isPlanChangeLoading } = useChangePlan();
  const [, setTick] = useState(0);

  const {
    device: { web },
  } = useTheme();
  const isWeb = useMediaQuery(web);
  const { t } = useTranslation();

  const { guid, date } = useParams();
  const {
    isLoading, data: { basketResult = [], basketSource = [] } = {}, getActivePlan, getPlanDay, update,
  } = useFetchBasket();
  const currentDate = new Date(date);
  const activePlan = getActivePlan({ date: currentDate, basketResult, basketSource });

  if (isLoading) {
    return <Loading />;
  }

  if (previewPlanId) {
    return (
      <PlanPreview
        plan={basketSource.find(({ id }) => id === previewPlanId)}
        onClose={() => setState(({ selectedPlanId: value }) => ({ selectedPlanId: value }))}
        onSelect={() => setState(({ previewPlanId: value }) => ({
          selectedPlanId: value,
          isModalOpen: true,
        }))} />
    );
  }

  const dayOfWeek = currentDate.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();

  const handleSubmit = () => {
    const updatedBasketResult = [];
    if (activePlan) {
      updatedBasketResult.push({
        planId: activePlan.id,
        selectedDate: '',
      });
    }
    if (selectedPlanId) {
      updatedBasketResult.push({
        planId: selectedPlanId,
        selectedDate: currentDate.toISOString().slice(0, 19),
      });
    }
    changePlan({
      guid,
      basketResult: updatedBasketResult,
      onComplete: (data) => {
        update(data);
        history.push(
          getPlanUrl({
            guid,
            date: new Date(Math.min(currentDate, getPlanDay({ basketResult, planId: selectedPlanId }) || Infinity)),
          }),
          { planChange: true },
        );
      },
    });
  };

  const handleTutorialFinish = () => {
    storage.set('tutorial:plan-change', true);
    setTick((val) => val + 1);
  };

  return (
    <>
      {isWeb ? (
        <ReactJoyride
          run={!storage.get('tutorial:plan-change')}
          callback={({ action }) => {
            if (action === 'reset') {
              storage.set('tutorial:plan-change', true);
            }
          }}
          continuous
          steps={t('planChange.tutorial', { returnObjects: true })} />
      ) : (
        <TutorialDialog images={[step1, step2, step3]} onFinish={handleTutorialFinish} isOpen={!storage.get('tutorial:plan-change')} />
      )}
      <HideOnMobile>
        <Header>
          <div>
            <LinkClose to={getPlanUrl({ guid, date: currentDate })}>
              <IconClose />
            </LinkClose>
            <PlanChangeTitle>
              <Trans i18nKey="planChange.header" />
            </PlanChangeTitle>
            <ButtonChange
              id="traveller-offer-update-button"
              disabled={selectedPlanId === undefined}
              onClick={() => setState(({ selectedPlanId: value }) => ({
                isModalOpen: true,
                selectedPlanId: value,
              }))}>
              <Trans i18nKey="planChange.buttonChangeText" />
            </ButtonChange>
          </div>
        </Header>
      </HideOnMobile>
      <Title>
        <div>
          <Trans
            i18nKey="planChange.title"
            values={{
              date: currentDate.toLocaleString('en', {
                month: 'long',
                day: 'numeric',
              }),
            }} />
        </div>
      </Title>
      <Description>
        <div>
          <Trans i18nKey="planChange.description" />
        </div>
      </Description>
      <PlanSummaryButton onClick={() => setState({})}>
        <PlanSummaryCard date={currentDate} plan={activePlan} isSelected={selectedPlanId === undefined} showDateCard />
      </PlanSummaryButton>
      <Plans>
        <div>
          <div>
            {activePlan ? (
              <>
                <HideOnMobile>
                  <PlanCard
                    className="tripster-card"
                    key={activePlan.id}
                    image={activePlan.themeImage}
                    designer={activePlan.tridDesigner}
                    title={activePlan.themeOfTheDay}
                    shortDescription={activePlan.shortDescription}
                    events={activePlan.cards}
                    planDay={currentDate}
                    currentDate={currentDate}
                    isSelected={selectedPlanId === undefined}
                    borderColor={selectedPlanId === undefined ? 'var(--green)' : 'var(--red-pink)'}
                    onClick={() => setState({})} />
                </HideOnMobile>
                <FreeDayCard
                  className="alternative-cards"
                  currentDate={currentDate}
                  isSelected={selectedPlanId === null}
                  disabled={basketResult.length === 1}
                  onClick={() => setState({ selectedPlanId: null })} />
              </>
            ) : (
              <HideOnMobile>
                <FreeDayCard
                  className="tripster-card"
                  currentDate={currentDate}
                  isSelected={selectedPlanId === undefined}
                  onClick={() => setState({})} />
              </HideOnMobile>
            )}
            {basketSource
              .filter((plan) => !activePlan || plan.id !== activePlan.id)
              .map((plan) => (
                <PlanCard
                  key={plan.id}
                  image={plan.themeImage}
                  designer={plan.tridDesigner}
                  title={plan.themeOfTheDay}
                  shortDescription={plan.shortDescription}
                  events={plan.cards}
                  planDay={getPlanDay({ basketResult, planId: plan.id })}
                  currentDate={currentDate}
                  isSelected={plan.id === selectedPlanId}
                  borderColor={plan.id === selectedPlanId ? 'var(--green)' : 'transparent'}
                  disabled={!plan.availableDays[dayOfWeek]}
                  className={plan.availableDays[dayOfWeek] ? 'alternative-cards' : undefined}
                  rowMoreClassName={plan.availableDays[dayOfWeek] ? 'row-more' : undefined}
                  onClick={() => setState({ selectedPlanId: plan.id })}
                  onMoreClick={(e) => {
                    e.stopPropagation();
                    window.scrollTo(0, 0);
                    setState(({ selectedPlanId: value }) => ({
                      previewPlanId: plan.id,
                      selectedPlanId: value,
                    }));
                  }} />
              ))}
          </div>
        </div>
      </Plans>
      <Footer>
        <FooterLinkClose to={getPlanUrl({ guid, date: currentDate })}>
          <IconClose />
        </FooterLinkClose>
        <FooterButtonUpdate
          id="traveller-offer-update-button"
          disabled={selectedPlanId === undefined}
          onClick={() => setState(({ selectedPlanId: value }) => ({
            isModalOpen: true,
            selectedPlanId: value,
          }))}>
          <Trans i18nKey="planChange.buttonChangeText" />
        </FooterButtonUpdate>
      </Footer>
      <Dialog
        open={Boolean(isModalOpen)}
        onClose={() => !isPlanChangeLoading
          && setState(({ selectedPlanId: value }) => ({
            isModalOpen: false,
            selectedPlanId: value,
          }))}>
        <DialogContent>
          <DialogMessage>
            <Trans
              i18nKey="planChange.dialogMessage"
              values={{
                date: currentDate.toLocaleString('en', {
                  month: 'short',
                  day: 'numeric',
                }),
              }} />
          </DialogMessage>
          <PlanSummaryCard plan={activePlan} date={currentDate} />
          <IconChange colorful />
          <PlanSummaryCard plan={basketSource.find(({ id }) => id === selectedPlanId)} date={currentDate} isSelected />
          <CancelButton
            type="button"
            disabled={isPlanChangeLoading}
            onClick={() => setState(({ selectedPlanId: value }) => ({
              isModalOpen: false,
              selectedPlanId: value,
            }))}>
            <Trans i18nKey="planChange.buttonCancelText" />
          </CancelButton>
          <SubmitButton disabled={isPlanChangeLoading} type="button" onClick={handleSubmit}>
            {isPlanChangeLoading ? <CircularProgress size={30} /> : <Trans i18nKey="planChange.buttonSubmitText" />}
          </SubmitButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Header = styled.div`
  & {
    @media ${({ theme }) => theme.device.web} {
      position: sticky;
      top: 0;
      padding: 16px 24px;
      box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
      display: flex;
      justify-content: center;
      background-color: var(--white);
      z-index: 1;

      > div {
        width: 100%;
        max-width: 940px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const LinkClose = styled(Link)`
  & {
    line-height: 0;
    padding: 0;
    background: var(--white);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PlanChangeTitle = styled.div`
  & {
    padding: 16px 24px 0 24px;
    font-size: 22px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.45;
    color: var(--dark-blue-grey);

    @media ${({ theme }) => theme.device.web} {
      padding: 0 0 0 12px;
      flex: 1;
    }
  }
`;

const ButtonChange = styled(Button)`
  && {
    display: none;

    @media ${({ theme }) => theme.device.web} {
      display: initial;
      width: 205px;
      height: 50px;
      border-radius: 4px;
      background-color: var(--dark-blue-grey);
      color: var(--white);
      font-size: 15px;
      border: none;

      :hover {
        background-color: var(--rhino);
      }
    }
  }
  &&&[disabled] {
    @media ${({ theme }) => theme.device.web} {
      background-color: var(--light-blue-grey);
      color: var(--white);
    }
  }
`;

const Title = styled.div`
  & {
    margin: 20px 24px 0 24px;
    font-family: 'Airbnb Cereal App Bold' !important;
    color: var(--slate);
    font-size: 30px;
    line-height: 40px;

    @media ${({ theme }) => theme.device.web} {
      margin: 50px 24px 0 24px;
      display: flex;
      justify-content: center;
      > div {
        flex: 1;
        max-width: 940px;
      }
    }
  }
`;

const Description = styled.div`
  & {
    margin: 0 24px;
    color: var(--slate);
    font-size: 13px;
    line-height: 32px;
    > div {
      font-family: 'Airbnb Cereal App Book' !important;
    }

    @media ${({ theme }) => theme.device.web} {
      display: flex;
      justify-content: center;
      > div {
        flex: 1;
        max-width: 940px;
      }
    }
  }
`;

const PlanSummaryButton = styled.button`
  & {
    padding: 0;
    margin: 20px 24px;
    width: calc(100vw - 48px);
    border: none;
    background: none;
    cursor: pointer;

    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const Plans = styled.div`
  & {
    margin-bottom: 80px;
    @media ${({ theme }) => theme.device.web} {
      display: flex;
      justify-content: center;
    }

    > div {
      width: calc(100vw - 36px);
      padding: 0 18px 20px 18px;
      overflow-x: scroll;

      > div {
        display: flex;
        padding-right: 18px;
        width: max-content;

        @media ${({ theme }) => theme.device.web} {
          padding-right: 0;
          flex-wrap: wrap;
          width: initial;
        }
      }

      ::-webkit-scrollbar {
        display: none;
      }

      @media ${({ theme }) => theme.device.web} {
        padding: 50px 18px;
        max-width: 960px;
      }
    }
  }
`;

const HideOnMobile = styled.div`
  & {
    display: none;
    @media ${({ theme }) => theme.device.web} {
      display: initial;
    }
  }
`;

const Footer = styled.div`
  & {
    position: fixed;
    bottom: 0;
    height: 65px;
    width: 100%;
    box-shadow: 0 -11px 24px 0 var(--dark-blue-grey-13);
    display: flex;
    align-items: stretch;
    background: var(--white);

    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const FooterLinkClose = styled(LinkClose)`
  & {
    width: 65px;
    cursor: pointer;
  }
`;

const FooterButtonUpdate = styled(Button)`
  && {
    flex: 1;
    padding: 0;
    background-color: var(--dark-blue-grey);
    border: none;
    font-size: 15px;
    line-height: 1.2;
    color: var(--white);
    border-radius: 0;

    :hover {
      background-color: var(--rhino);
    }
  }
  &&&[disabled] {
    background-color: var(--light-blue-grey);
    color: var(--white);
  }
`;

const DialogContent = styled.div`
  & {
    padding: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 20px;

    @media ${({ theme }) => theme.device.web} {
      padding: 64px;
    }

    > div {
      grid-column: span 2;
      width: 100%;
    }

    > svg {
      grid-column: span 2;
      justify-self: center;
    }
  }
`;

const DialogMessage = styled.div`
  & {
    font-size: 16px;
    line-height: 1.44;
    text-align: center;
    color: var(--dark-blue-grey);
    font-family: 'Airbnb Cereal App Book' !important;
    > strong {
      font-family: 'Airbnb Cereal App Bold' !important;
    }
  }
`;

const CancelButton = styled(Button)`
  && {
    padding: 13px;
    background-color: var(--white);
    border-radius: 4px;
    border: solid 1px var(--very-light-blue);
    font-size: 15px;
    line-height: 1.6;
    color: var(--dark-blue-grey);
  }
`;

const SubmitButton = styled(Button)`
  && {
    padding: 13px;
    background-color: var(--dark-blue-grey);
    border-radius: 4px;
    border: solid 1px var(--very-light-blue);
    font-size: 15px;
    line-height: 1.6;
    color: var(--white);

    :hover {
      background-color: var(--rhino);
    }

    svg {
      color: var(--white);
    }
  }
`;
