const safeParse = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

export const storage = {
  get: (key) => safeParse(sessionStorage.getItem(key)),
  set: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
};
