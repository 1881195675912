const {
  REACT_APP_API_URL: API_URL = 'https://api.heytripster.com',
  REACT_APP_HOT_JAR_ID: HOT_JAR_ID = 1533675,
  REACT_APP_HOT_JAR_SNIPPET_VERSION: HOT_JAR_SNIPPET_VERSION = 6,
  REACT_APP_GTM_ID: GTM_ID = 'GTM-MC3DN9C',
} = process.env;

export const config = {
  API_URL,
  HOT_JAR_ID,
  HOT_JAR_SNIPPET_VERSION,
  GTM_ID,
};
