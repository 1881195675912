import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

import { IconSelected } from 'components';

export const FreeDayCard = ({
  currentDate, isSelected, disabled, onClick, className,
}) => (
  <FreeDay disabled={disabled} onClick={onClick} isSelected={isSelected}>
    <div className={className}>
      <FreeDayTitle>
        <Trans i18nKey="planChange.freeDayTitle" />
      </FreeDayTitle>
      <FreeDayDescription>
        <Trans
          i18nKey="planChange.freeDayDescription"
          values={{
            date: currentDate.toLocaleString('en', {
              month: 'long',
              day: 'numeric',
            }),
          }} />
      </FreeDayDescription>
    </div>
    {isSelected && <Selected />}
  </FreeDay>
);

FreeDayCard.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

FreeDayCard.defaultProps = {
  isSelected: false,
  disabled: false,
  className: undefined,
  onClick: undefined,
};

const FreeDay = styled.button`
  & {
    position: relative;
    width: 260px;
    height: 314px;
    margin: 6px;
    padding: 24px;
    flex: 0 0 auto;
    border-radius: 4px;
    border: solid 1px var(${({ isSelected }) => (isSelected ? '--green' : '--light-periwinkle')});
    background-color: var(--pale-grey);
    cursor: pointer;

    @media ${({ theme }) => theme.device.web} {
      width: 300px;
      margin: 10px;
    }
  }
`;

const FreeDayTitle = styled.div`
  & {
    font-size: 16px;
    line-height: 1.44;
    font-family: 'Airbnb Cereal App Bold' !important;
    color: var(--dark-blue-grey);
  }
`;

const FreeDayDescription = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;

    > strong {
      font-family: 'Airbnb Cereal App Bold' !important;
      font-weight: normal;
    }
  }
`;

const Selected = styled(IconSelected)`
  & {
    position: absolute;
    right: -1px;
    top: -1px;
  }
`;
