import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

import { IconClose, PlanSummary, Event } from 'components';
import { Button } from '@material-ui/core';

export const PlanPreview = ({
  plan: {
    tridDesigner: {
      fullName, image, tdRating, tdRatingCount,
    },
    themeOfTheDay,
    shortDescription,
    difficulty,
    planAvaregePrice,
    walking,
    cards,
    themeImage,
  },
  onSelect,
  onClose,
}) => (
  <>
    <Header>
      <Title>
        <Trans i18nKey="planChange.planPreview.title" />
      </Title>
      <CloseButton onClick={onClose}>
        <IconClose />
      </CloseButton>
    </Header>
    <Content>
      <Cover background={themeImage ? `url(${themeImage})` : 'var(--seafoam-blue)'} />
      <Events>
        {cards.map(({
          startTime, nameOfTheEvent, reason, eventType, discrict,
        }, i) => (
          <Event
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            district={discrict}
            name={nameOfTheEvent}
            reason={reason}
            time={startTime}
            type={eventType}
            isLast={i === cards.length - 1} />
        ))}
      </Events>
      <HideOnMobile>
        <PlanSummary
          title={themeOfTheDay}
          description={shortDescription}
          planAvaregePrice={planAvaregePrice}
          planCurrency="₺"
          difficulty={difficulty}
          walking={walking}
          designer={{
            image,
            name: fullName,
            rating: tdRating,
            ratingCount: tdRatingCount,
          }}>
          <SelectButton onClick={onSelect}>
            <Trans i18nKey="planChange.planPreview.selectPlan" />
          </SelectButton>
        </PlanSummary>
      </HideOnMobile>
    </Content>
  </>
);

PlanPreview.propTypes = {
  plan: PropTypes.shape({
    tridDesigner: PropTypes.shape({
      fullName: PropTypes.string,
      image: PropTypes.string,
      tdRating: PropTypes.number,
      tdRatingCount: PropTypes.number,
    }),
    themeOfTheDay: PropTypes.string,
    shortDescription: PropTypes.string,
    difficulty: PropTypes.string,
    planAvaregePrice: PropTypes.string,
    planCurrency: PropTypes.string,
    walking: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape(Event.propTypes)),
    themeImage: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

PlanPreview.defaultProps = {
  onSelect: undefined,
  onClose: undefined,
};

const Header = styled.div`
  & {
    top: 0;
    position: sticky;
    z-index: 1;
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
  }
`;

const Title = styled.div`
  & {
    font-family: 'Airbnb Cereal App Bold' !important;
    font-size: 22px;
    color: var(--dark-blue-grey);
  }
`;

const CloseButton = styled.button`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const Content = styled.div`
  & {
    @media ${({ theme }) => theme.device.web} {
      padding: 0 26px;
      display: grid;
      grid-template-areas:
        'cover cover'
        'events summary';
      row-gap: 40px;
      column-gap: 54px;
    }
  }
`;

const Cover = styled.div`
  & {
    display: none;
    @media ${({ theme }) => theme.device.web} {
      margin: 0 -26px;
      display: initial;
      grid-area: cover;
      height: 324px;
      width: calc(100% + 52px);
      background: ${({ background }) => background};
      background-size: cover;
    }
  }
`;

const Events = styled.div`
  & {
    padding: 28px 26px;
    grid-area: events;
    @media ${({ theme }) => theme.device.web} {
      padding: 0;
      margin-bottom: 50px;
      max-width: 540px;
      justify-self: flex-end;
    }
  }
`;

const SelectButton = styled(Button)`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border-radius: 4px;
    background-color: var(--dark-blue-grey);
    border: none;
    line-height: 1.2;
    font-size: 15px;
    color: var(--white);

    :hover {
      background-color: var(--rhino);
    }
  }
`;

const HideOnMobile = styled.div`
  & {
    display: none;
    @media ${({ theme }) => theme.device.web} {
      display: initial;
    }
  }
`;
