import React from 'react';

/* eslint-disable max-len */
export const IconLock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <g fill="#1B2D41" fillRule="evenodd">
      <path d="M14.063 25c-.863 0-1.563.7-1.563 1.563V37.5c0 .863.7 1.563 1.563 1.563h21.874c.863 0 1.563-.7 1.563-1.563V26.562c0-.862-.7-1.562-1.563-1.562H14.064zm0-3.125h21.874a4.687 4.687 0 0 1 4.688 4.688V37.5a4.687 4.687 0 0 1-4.688 4.688H14.064A4.687 4.687 0 0 1 9.375 37.5V26.562a4.687 4.687 0 0 1 4.688-4.687z" />
      <path d="M18.75 23.438a1.563 1.563 0 0 1-3.125 0v-6.25a9.375 9.375 0 0 1 18.75 0v6.25a1.563 1.563 0 0 1-3.125 0v-6.25a6.25 6.25 0 1 0-12.5 0v6.25z" />
    </g>
  </svg>
);
