/* eslint-disable max-len */

export const en = {
  translation: {
    main: {
      treeText: 'Did you know we plant 1 tree for every plan you get!',
      treeDetail: '10 Million trees on the way!',
    },
    header: {
      support: 'Live Support',
    },
    welcome: {
      title: 'Welcome to Tripster<br/><strong>{{name}}!</strong>',
      text:
        'We picked the best plans from local trip designers in paralel with your evalualtion.<br/>Click "Discover" to browse through your suggested plans for your trip to <strong>Istanbul</strong><br/> between <strong>{{dateRange}}!</strong>',
      discoverLink: 'Discover my itinerary',
    },
    expired: {
      title: 'Welcome to Tripster<br/><strong>{{name}}!</strong>',
      text: 'Your offer has been expired.<br/>If you would like to get new offer, please contact with us!',
      link: 'Contact us',
    },
    error: {
      title: 'Welcome to Tripster!',
      text: 'Your offer cannot be reached right now.<br/>Please try again or contact with us!',
      link: 'Contact us',
    },
    notFound: {
      title: 'Welcome to Tripster!',
      text: 'This link is invalid.<br/>Please contact with us!',
      link: 'Contact us',
    },
    planDetail: {
      title: 'Plan Summary',
      reasonTitle: 'Why should it be done? (P.S.)',
      effort: 'Avg. Cost',
      difficulty: 'Difficulty',
      walking: 'Walking',
      changePlans: 'Change Plans',
      next: 'Next',
      review: 'Review',
      error: {
        title: 'Sorry, we can only display plans as previews at this stage',
        message: 'Once you unlocked the plans at the last page, you’ll be able to see full details and able to connect with your Trip Designers',
      },
      planChangeDialog: {
        message: 'Your plan has been updated',
        button: 'Continue',
      },
      tutorial: [
        {
          title: 'Meet your trip designer',
          content: 'Before and during your trip, you can get in touch with your trip designer for more information or to ask any questions',
          target: '#designer-row',
        },
        {
          title: 'Check your plan details',
          content: "Local trip designers briefly explain the event. You'll receive full details after completing the process",
          target: '.event-card',
        },
        {
          title: 'Click to see alternative plans',
          content: "You can browse all suggested available plans based on your requirements and choose another one if you'd prefer it",
          target: '#traveller-offer-change-plans-button',
        },
        {
          title: 'Your whole trip planned for you',
          content: 'Browse through your itinerary, packed with plans chosen for you based on your preferences',
          target: '#traveller-offer-next-button',
        },
      ],
      noPlan: {
        cover: 'I GOT MY OWN PLANS',
        themeOfTheDay: 'I got my own plans for today.',
        shortDescription: "Thanks Tripster, I'll pick other plans for different days but not for today.",
        planAvaregePrice: '200',
        planCurrency: '₺',
        difficulty: 'Medium',
        walking: '3-4 Km',
        cards: [
          {
            nameOfTheEvent: "I've lived a life that's full",
            startTime: '09:30',
            reason: '"My Way" is a song popularized in 1969 by Frank Sinatra.',
            discrict: 'Istanbul',
            type: 1,
          },
          {
            nameOfTheEvent: "I've travelled each and every highway",
            startTime: '11:20',
            reason:
              'Its lyrics were written by Paul Anka and set to the music of the French song "Comme d\'habitude" composed and written by French songwriter Jacques Revaux',
            discrict: 'Istanbul',
            type: 2,
          },
          {
            nameOfTheEvent: 'and more, much more than this',
            startTime: '12:40',
            reason: "Anka's English lyrics are unrelated to the original French song.",
            discrict: 'Istanbul',
            type: 3,
          },
          {
            nameOfTheEvent: 'I did it my way',
            startTime: '13:30',
            reason: 'The song was a success for a variety of performers including Sinatra, Elvis Presley, and Sid Vicious.',
            discrict: 'Istanbul',
            type: 4,
          },
          {
            nameOfTheEvent: 'I planned each chartered course',
            startTime: '15:00',
            reason: 'Sinatra\'s version of "My Way" spent 75 weeks in the UK Top 40, a record which still stands.',
            discrict: 'Istanbul',
            type: 5,
          },
          {
            nameOfTheEvent: 'Each careful step along the by-way',
            startTime: '17:30',
            reason: "The song is popularly associated with nostalgia to an individual's lifetime of events.",
            discrict: 'Istanbul',
            type: 6,
          },
          {
            nameOfTheEvent: 'And more, much more than this',
            startTime: '19:30',
            reason:
              'Toward the end of his career, Elvis added this to his concert repertoire. After his death in 1977, a live version was released as a single, going to #22 in the US and #9 in the UK.',
            discrict: 'Istanbul',
            type: 1,
          },
          {
            nameOfTheEvent: 'I did it my way',
            startTime: '23:00',
            reason: '"My Way" is also a popular karaoke song around the world.',
            discrict: 'Istanbul',
            type: 1,
          },
        ],
      },
    },
    designer: {
      languages: 'Speaks {{languages}}',
      review: '{{count}} Review',
      review_plural: '{{count}} Reviews',
      favouriteSong: 'Favourite Song',
      plans: 'Plans',
      trees: 'Planted Trees',
      interestedAreas: 'Area of Interests',
      askAbout: 'Ask About',
      favouritePlaces: 'Favourite Places',
      profession: 'Profession',
      messagingStatus: 'Messaging Status',
    },
    planChange: {
      header: 'Change Plan',
      title: 'You are now changing {{date}}',
      description: 'You can swap plans with the one you like or pick "Free My Day" not to get any plans for today.',
      planSummaryCard: {
        freeDayTitle: 'Free Day',
        freeDayThumbnail: 'NO PLANS',
        freeDayDescription: "You don't have any plan for {{date}}.",
      },
      freeDayTitle: 'FREE MY DAY',
      freeDayDescription: 'Choose this plan if you have other plans for <strong>{{date}}</strong>.',
      more: '<strong>+{{count}}</strong> More',
      planDayWarning: 'This plan is selected on {{date}}.',
      unavailableWarning: 'You cannot select this plan on {{date}}.',
      dialogMessage: 'Would you like to update your plan for <strong>{{date}}</strong>?',
      buttonSubmitText: 'Yes',
      buttonCancelText: 'Not Now',
      buttonChangeText: 'Update',
      planPreview: {
        title: 'Plan Summary',
        selectPlan: 'Select Plan',
      },
      tutorial: [
        {
          title: "You've got other plans?",
          content: "Pick this card if there is a day that you don't want one of our plans.",
          target: '.alternative-cards',
        },
        {
          title: 'Customize your trip',
          content: 'To amend your plan just click on another plan that you would like to view. For more details of that plan, click +4 more',
          target: '.row-more',
        },
        {
          title: 'Click update to swap plans',
          content: "By clicking update, you'll be swapping the plan we suggested for the one you just picked",
          target: '#traveller-offer-update-button',
        },
      ],
    },
    payment: {
      title: 'Review and Pay',
      description:
        'Here you may find your plans all together. You’ll have full access all the plans you picked here with a mail you’ll receive right after you click “Confirm and Pay” button.',
      designer: 'by {{designer}}',
      tree: 'You are planting 1 tree in Istanbul by getting plans from locals.',
      tree_plural: 'You are planting <strong>{{count}} trees</strong> in Istanbul by getting plans from locals.',
      formTitle: 'Pay with',
      labelName: 'Name on Card',
      labelCard: 'Card Number',
      labelExpire: 'MM/YY',
      labelCVV: 'CVV',
      disclaimer: 'By completing this payment, you agree to the <0>Personal Data Policy</0>, the <1>Distance Sales Agreement</1>.',
      submit: 'Confirm and Pay',
      total: 'Total',
      required: 'Required',
      invalidCardNumber: 'Invalid card number',
      invalidCardDate: 'Invalid date',
      invalidCVV: 'Invalid CVV',
      receiptTitle: 'Picked {{count}} plan',
      receiptTitle_plural: 'Picked {{count}} plans',
      receiptPlan: '{{count}} plan',
      receiptPlan_plural: '{{count}} plans',
      receiptServiceFee: 'Service fee',
      freeCancellationTitle: 'Free cancellation for 10 days',
      freeCancellationDescription: 'Get full refund if you contact us within 10 days of payment.',
      discountTitle: 'Coupon',
      discountDescription: 'Click "Apply" button to get instant {{discount}}% discount for {{count}} plan you picked.',
      discountDescription_plural: 'Click "Apply" button to get instant {{discount}}% discount for {{count}} plans you picked.',
      discountButton: 'Apply',
      discountApplied: 'Discount applied',
    },
    completed: {
      title: 'You have successfully completed your payment.',
      unlockCard: {
        title: 'We are going to share your full plan in 12 hours.',
        text: 'If you would like to get in touch, feel free to contact from  <0>info@heytripster.com</0>',
      },
      treeCardText: 'You are planting <strong>{{count}} trees</strong> in Istanbul by getting plans from locals.',
      formTitle: 'Who else is travelling with you?<br/>Share your plans with them!',
      labelName: 'Name',
      labelSurname: 'Surname',
      labelEmail: 'Email',
      required: 'Required',
      add: '+ Add Another',
      submit: 'Share my Plans',
      successTitle: 'Thank you',
      successText: 'We look forward to seeing you and your friends.',
    },
    errorDialog: {
      ok: 'Ok',
    },
  },
};
