import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconSupport = ({ className }) => (
  <svg
    className={className}
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>support_icon</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="7_Free_my_Day" transform="translate(-1015.000000, -30.000000)" fill="#1B2D41">
        <g
          id="01-Fragments-/-Icons-/-32px-/-Comment-/-Dark"
          transform="translate(1009.000000, 24.000000)">
          <g id="message-square" transform="translate(6.000000, 6.000000)">
            <path
              d="M4.29289322,14.2928932 C4.4804296,14.1053568 4.73478351,14 5,14 L17,14 C17.5522847,14 18,13.5522847 18,13 L18,3 C18,2.44771525 17.5522847,2 17,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,16.5857864 L4.29289322,14.2928932 Z M5.41421356,16 L1.70710678,19.7071068 C1.07714192,20.3370716 0,19.8909049 0,19 L0,3 C0,1.34314575 1.34314575,0 3,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,13 C20,14.6568542 18.6568542,16 17,16 L5.41421356,16 Z"
              id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconSupport.propTypes = {
  className: PropTypes.string,
};

IconSupport.defaultProps = {
  className: undefined,
};
