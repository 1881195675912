import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router';

import {
  IconConversation, IconStar, IconHeart, IconQuotation, Loading, Header,
} from 'components';
import { useFetchBasket } from 'hooks';
import { getPlanUrl, getWelcomeUrl } from 'navigation';

const renderTag = (val) => <div key={val}>{val}</div>;

export const DesignerDetail = () => {
  const { planId, guid } = useParams();
  const { data: { basketSource = [], basketResult = [] } = {}, isLoading } = useFetchBasket();

  if (isLoading) {
    return <Loading />;
  }

  const {
    tridDesigner: {
      fullName,
      image,
      tdRating,
      tdRatingCount,
      tdLanguages,
      tdTrees,
      tdPlanCount,
      shortDescription,
      favoriteSong,
      interestedAreas,
      issuesKnows,
      favorites,
      profession,
      messagingStatus,
    },
  } = basketSource.find(({ id }) => id === Number(planId)) || {};

  const { selectedDate } = basketResult.find((item) => item.planId === Number(planId)) || {};

  return (
    <>
      <Header
        url={selectedDate ? getPlanUrl({ guid, date: selectedDate }) : getWelcomeUrl({ guid })} />
      <Content>
        <Card>
          <DesignerProfile>
            <DesignerImage alt="designer" src={image} />
            <DesignerName>{fullName}</DesignerName>
            <DesignerStar>
              <IconStar />
              <strong>{tdRating.toFixed(1)}</strong>
              <div>{`(${tdRatingCount})`}</div>
            </DesignerStar>
          </DesignerProfile>
          <Row>
            <IconConversation />
            <div>
              <Trans i18nKey="designer.languages" values={{ languages: tdLanguages.join(', ') }} />
            </div>
          </Row>
          <Row>
            <IconHeart />
            <div>
              <Trans i18nKey="designer.review" values={{ count: tdRatingCount }} />
            </div>
          </Row>
          <Statistics>
            <StatisticsValue>{tdPlanCount}</StatisticsValue>
            <StatisticsValue>{tdTrees}</StatisticsValue>
            <StatisticsTitle>
              <Trans i18nKey="designer.plans" />
            </StatisticsTitle>
            <StatisticsTitle>
              <Trans i18nKey="designer.trees" />
            </StatisticsTitle>
          </Statistics>
          <DescriptionRow>
            <IconQuotation />
            <Paragraph>{shortDescription}</Paragraph>
          </DescriptionRow>
        </Card>
        <FunFacts>
          {favoriteSong && (
            <>
              <Title>
                <Trans i18nKey="designer.favouriteSong" />
              </Title>
              <FavouriteSong
                title="Favourite Song"
                src={favoriteSong}
                width="300"
                height="80"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media" />
            </>
          )}
          <Title>
            <Trans i18nKey="designer.interestedAreas" />
          </Title>
          <Tags>{interestedAreas.map(renderTag)}</Tags>
          <Title>
            <Trans i18nKey="designer.askAbout" />
          </Title>
          <Tags>{issuesKnows.map(renderTag)}</Tags>
          <Title>
            <Trans i18nKey="designer.favouritePlaces" />
          </Title>
          <Tags>{favorites.map(renderTag)}</Tags>
          <Title>
            <Trans i18nKey="designer.profession" />
          </Title>
          <Paragraph>{profession}</Paragraph>
          <Title>
            <Trans i18nKey="designer.messagingStatus" />
          </Title>
          <Paragraph>{messagingStatus}</Paragraph>
        </FunFacts>
      </Content>
    </>
  );
};

const Content = styled.div`
  & {
    padding: 28px 24px;
    overflow: scroll;
    @media ${({ theme }) => theme.device.web} {
      display: grid;
      padding: 28px 40px;
      column-gap: 110px;
      grid-template-columns: repeat(2, minmax(100px, 400px));
      justify-content: center;
    }
  }
`;

const Card = styled.div`
  & {
    @media ${({ theme }) => theme.device.web} {
      padding: 24px;
      border-radius: 4px;
      box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
      background-color: var(--white);
      height: fit-content;
    }
  }
`;

const DesignerProfile = styled.div`
  & {
    display: grid;
    grid-template-areas:
      'image name'
      'image star';
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    column-gap: 13px;
    text-decoration: none;
    margin-bottom: 20px;
  }
`;

const DesignerImage = styled.img`
  & {
    grid-area: image;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border: solid 1px var(--white);
    align-self: center;
    color: var(--dark-blue-grey);
  }
`;

const DesignerName = styled.div`
  & {
    grid-area: name;
    font-size: 22px;
    align-self: flex-end;
    color: var(--dark-blue-grey);
  }
`;

const DesignerStar = styled.div`
  & {
    grid-area: star;
    font-size: 12px;
    font-family: 'Airbnb Cereal App Light' !important;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 4px;
    color: var(--dark-blue-grey);
  }
`;

const Row = styled.div`
  & {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    padding-top: 15px;
    margin: 0 -3px;
    > * {
      margin: 0 3px;
    }
  }
`;

const Statistics = styled.div`
  & {
    display: grid;
    row-gap: 2px;
    column-gap: 110px;
    margin: 40px 0;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
  }
`;

const StatisticsValue = styled.div`
  & {
    text-align: center;
    font-size: 27px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.19;
    color: var(--dark-blue-grey);
  }
`;

const StatisticsTitle = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    text-align: center;
    color: var(--slate);
  }
`;

const DescriptionRow = styled.div`
  & {
    display: flex;

    > svg {
      margin-right: 10px;
    }
  }
`;

const Paragraph = styled.div`
  & {
    margin-top: 6px;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const FunFacts = styled.div`
  & {
    @media ${({ theme }) => theme.device.web} {
      margin: -80px 0 28px 0;
    }
  }
`;

const Title = styled.div`
  & {
    margin: 64px 0 12px 0;
    font-size: 22px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.45;
    color: var(--dark-blue-grey);

    @media ${({ theme }) => theme.device.web} {
      margin: 80px 0 12px 0;
    }
  }
`;

const FavouriteSong = styled.iframe`
  & {
    width: 100%;
  }
`;

const Tags = styled.div`
  & {
    display: flex;
    flex-wrap: wrap;
    margin: -8px -8px;

    > * {
      margin: 8px 8px;
      padding: 10px 18px;
      background-color: var(--pale-grey);
      font-size: 13px;
      line-height: 1.85;
      text-align: center;
      color: var(--dark-blue-grey);
      white-space: nowrap;
    }
  }
`;
