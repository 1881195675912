import React from 'react';

/* eslint-disable max-len */
export const IconSwitch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
    <g fill="none" fillRule="evenodd">
      <rect width="44" height="44" fill="#F6F6F7" rx="4" />
      <g fill="#1B2D41">
        <path d="M29.069 30.555V14.97l2.918 2.918a.886.886 0 1 0 1.253-1.253l-4.43-4.431a.886.886 0 0 0-1.254 0l-4.43 4.431a.883.883 0 0 0 0 1.253.886.886 0 0 0 1.252 0l2.919-2.918v15.585a.886.886 0 0 0 1.772 0zM20.587 26.542a.886.886 0 0 0-1.253-1.254l-2.918 2.918V12.622a.886.886 0 0 0-1.773 0v15.584l-2.918-2.918a.886.886 0 1 0-1.253 1.254l4.43 4.43a.886.886 0 0 0 1.254 0l4.431-4.43z" />
      </g>
    </g>
  </svg>
);
