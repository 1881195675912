import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { IconStar, IconLock, IconSelected } from 'components';
import { EventsSummary } from './EventsSummary';

export const PlanCard = ({
  image,
  designer,
  title,
  shortDescription,
  events,
  planDay,
  currentDate,
  isSelected,
  borderColor,
  disabled,
  className,
  rowMoreClassName,
  onClick,
  onMoreClick,
}) => (
  <Plan onClick={onClick} disabled={disabled} borderColor={borderColor}>
    <Cover src={image} alt="cover" />
    <Summary>
      <DesignerImage alt="designer" src={designer.image} />
      <DesignerName>{designer.fullName}</DesignerName>
      <DesignerRate>
        <IconStar />
        <div>{designer.tdRating.toFixed(1)}</div>
        <div>{`(${designer.tdRatingCount})`}</div>
      </DesignerRate>
      <Title className={className}>{title}</Title>
      <Description>{shortDescription}</Description>
    </Summary>
    <Content style={planDay ? { paddingTop: 54 } : undefined}>
      <EventsSummary events={events} maxCount={planDay ? 2 : 3} onMoreClick={onMoreClick} rowMoreClassName={rowMoreClassName} />
      {disabled && (
        <UnavailablePlan>
          <IconLock />
          <div>
            <Trans
              i18nKey="planChange.unavailableWarning"
              values={{
                date: currentDate.toLocaleString('en', {
                  month: 'short',
                  day: 'numeric',
                }),
              }} />
          </div>
        </UnavailablePlan>
      )}
      {planDay && (
        <PlanDayWarning>
          <Trans i18nKey="planChange.planDayWarning" values={{ date: planDay.toLocaleString('en', { month: 'short', day: 'numeric' }) }} />
        </PlanDayWarning>
      )}
    </Content>
    {isSelected && <Selected />}
  </Plan>
);

PlanCard.propTypes = {
  image: PropTypes.string,
  designer: PropTypes.shape({
    image: PropTypes.string,
    fullName: PropTypes.string,
    tdRating: PropTypes.number,
    tdRatingCount: PropTypes.number,
  }),
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  events: EventsSummary.propTypes.events,
  planDay: PropTypes.instanceOf(Date),
  currentDate: PropTypes.instanceOf(Date).isRequired,
  isSelected: PropTypes.bool,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  rowMoreClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMoreClick: PropTypes.func,
};

PlanCard.defaultProps = {
  image: '',
  designer: {
    tdRating: 0,
    tdRatingCount: 0,
  },
  shortDescription: '',
  title: '',
  events: [],
  planDay: undefined,
  isSelected: false,
  borderColor: 'transparent',
  className: undefined,
  rowMoreClassName: undefined,
  disabled: false,
  onClick: undefined,
  onMoreClick: undefined,
};

const Plan = styled.button`
  & {
    position: relative;
    width: 260px;
    height: 316px;
    margin: 6px;
    padding: 0;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
    border: solid 1px ${({ borderColor }) => borderColor};
    cursor: pointer;

    @media ${({ theme }) => theme.device.web} {
      width: 300px;
      margin: 10px;
    }
  }
`;

const Cover = styled.img`
  & {
    width: 100%;
    height: 133px;
    object-fit: cover;
    object-position: 0 0;
  }
`;

const Summary = styled.div`
  & {
    position: absolute;
    top: 0;
    width: calc(100% - 20px);
    height: 107px;
    background-image: linear-gradient(to bottom, var(--black-0) -58%, var(--black-76));
    padding: 14px 10px 12px 10px;
    display: grid;
    grid-template-areas:
      'image designer-name rate'
      'title title title'
      'description description description';
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content max-content max-content;
    column-gap: 8px;
    row-gap: 4px;
    color: var(--white);
    justify-content: flex-start;
    align-items: center;
  }
`;

const DesignerImage = styled.img`
  & {
    grid-area: image;
    width: 27px;
    height: 27px;
    border: solid 1px var(--white);
    border-radius: 50%;
  }
`;

const DesignerName = styled.div`
  & {
    grid-area: designer-name;
    font-size: 13px;
    line-height: 1.85;
    align-self: center;
    justify-self: flex-start;
  }
`;

const DesignerRate = styled.div`
  & {
    grid-area: rate;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, max-content);
    column-gap: 2px;

    > div {
      font-family: 'Airbnb Cereal App Light' !important;
    }

    > div:nth-child(2) {
      font-family: 'Airbnb Cereal App Bold' !important;
    }
  }
`;

const Title = styled.div`
  & {
    grid-area: title;
    text-align: start;
    font-size: 15px;
    line-height: 1.6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Description = styled.div`
  & {
    grid-area: description;
    text-align: start;
    font-size: 12px;
    font-family: 'Airbnb Cereal App Light' !important;
    line-height: 1.33;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const Content = styled.div`
  & {
    position: relative;
    padding: 12px 16px;
    width: calc(100% - 32px);
    flex: 1;
  }
`;

const PlanDayWarning = styled.div`
  & {
    position: absolute;
    top: 12px;
    width: calc(100% - 32px);
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--pale);
    font-size: 11px;
    color: var(--yellow-orange);
  }
`;

const UnavailablePlan = styled.div`
  & {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 179px;
    padding: 0 24px;
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: var(--dark-blue-grey);
  }
`;

const Selected = styled(IconSelected)`
  & {
    position: absolute;
    right: -1px;
    top: -1px;
  }
`;
