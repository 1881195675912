import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { Image } from './Image';

export const Main = ({
  title, text, link, url, useNativeLink,
}) => (
  <PageContainer>
    <WelcomeCard>
      <LogoContainer>
        <Logo alt="logo" fileName="logo" />
      </LogoContainer>
      <WelcomeTitle>{title}</WelcomeTitle>
      <WelcomeText>{text}</WelcomeText>
      <DiscoverLink href={url} to={url} as={useNativeLink ? undefined : Link}>
        <DiscoverButton>{link}</DiscoverButton>
      </DiscoverLink>
    </WelcomeCard>
    <Card>
      <TreeImage alt="hand holding tree" fileName="tree" />
      <TreeText>
        <Trans i18nKey="main.treeText" />
      </TreeText>
      <TreeDetail>
        <Trans i18nKey="main.treeDetail" />
      </TreeDetail>
    </Card>
  </PageContainer>
);

Main.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  useNativeLink: PropTypes.bool,
};

Main.defaultProps = {
  useNativeLink: false,
};

const PageContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 57px 36px;
  }
`;

const WelcomeCard = styled.div`
  & {
    width: 100%;
    max-width: 460px;
    @media ${({ theme }) => theme.device.web} {
      width: 304px;
      padding: 66px 78px 30px 78px;
      border-radius: 4px;
      box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
      background-color: var(--white);
      margin-bottom: 42px;
    }
  }
`;

const LogoContainer = styled.div`
  & {
    display: flex;
    justify-content: center;
  }
`;

const Logo = styled(Image)`
  & {
    width: 198px;
    height: 42px;

    @media ${({ theme }) => theme.device.web} {
      width: 228px;
      height: 48px;
    }
  }
`;

const WelcomeTitle = styled.div`
  & {
    margin: 42px 0;
    font-size: 22px;
    line-height: 1.45;
    text-align: center;
    color: var(--dark-blue-grey);
    font-family: 'Airbnb Cereal App Book' !important;
    > strong {
      font-weight: normal;
      font-family: 'Airbnb Cereal App Bold' !important;
    }
  }
`;

const WelcomeText = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    text-align: center;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
    > strong {
      font-family: 'Airbnb Cereal App Extra Bold' !important;
    }
  }
`;

const DiscoverLink = styled.a`
  & {
    text-decoration: none;
  }
`;

const DiscoverButton = styled(Button)`
  && {
    display: block;
    margin: 48px 0;
    padding: 12px 8px;
    width: 100%;
    border-radius: 4px;
    background-color: var(--dark-blue-grey);
    text-align: center;
    color: var(--white);
    font-size: 15px;

    :hover {
      background-color: var(--rhino);
    }
  }
`;

const Card = styled.div`
  & {
    justify-self: center;
    width: 100%;
    max-width: 460px;
    display: grid;
    align-items: center;
    padding: 6px 0;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
    grid-template-areas:
      'image text'
      'image detail';
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const TreeImage = styled(Image)`
  & {
    grid-area: image;
  }
`;

const TreeText = styled.div`
  & {
    grid-area: text;
    align-self: flex-end;
    margin: 22px 22px 2px 22px;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
  }
`;

const TreeDetail = styled.div`
  & {
    grid-area: detail;
    align-self: flex-start;
    margin: 2px 22px 22px 22px;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;
