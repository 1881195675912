import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export const Image = ({ fileName, alt, className }) => (
  <img
    className={className}
    src={require(`assets/${fileName}.png`)}
    srcSet={
      `${require(`assets/${fileName}.png`)}, `
      + `${require(`assets/${fileName}@2x.png`)} 2x, `
      + `${require(`assets/${fileName}@3x.png`)} 3x`
    }
    alt={alt} />
);

Image.propTypes = {
  fileName: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: undefined,
};
