import React from 'react';

/* eslint-disable max-len */
export const IconStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
    <path
      fill="#FFC233"
      fillRule="evenodd"
      d="M6.535 11.245L4.09 12.529a1 1 0 0 1-1.45-1.054l.466-2.72a1 1 0 0 0-.288-.886L.842 5.942a1 1 0 0 1 .555-1.706l2.732-.396a1 1 0 0 0 .753-.548L6.103.817a1 1 0 0 1 1.794 0l1.221 2.475a1 1 0 0 0 .753.548l2.732.396a1 1 0 0 1 .555 1.706L11.18 7.87a1 1 0 0 0-.288.885l.467 2.721a1 1 0 0 1-1.451 1.054l-2.444-1.284a1 1 0 0 0-.93 0z" />
  </svg>
);
