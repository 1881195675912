import React from 'react';

/* eslint-disable max-len */
export const IconConversation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#1B2D41"
      fillRule="evenodd"
      d="M6.433 12.683a.625.625 0 0 1 .442-.183h7.5c.345 0 .625-.28.625-.625v-6.25A.625.625 0 0 0 14.375 5h-8.75A.625.625 0 0 0 5 5.625v8.491l1.433-1.433zm.7 1.067l-2.316 2.317a.625.625 0 0 1-1.067-.442v-10c0-1.036.84-1.875 1.875-1.875h8.75c1.036 0 1.875.84 1.875 1.875v6.25c0 1.036-.84 1.875-1.875 1.875H7.134z" />
  </svg>
);
