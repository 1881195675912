import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { IconSwitch } from 'components';
import { Link } from 'react-router-dom';

export const Plan = ({
  date,
  changeLink,
  plan: {
    themeImage,
    themeOfTheDay,
    tridDesigner: { fullName },
  },
}) => (
  <PlanCard>
    <DateCardBackground url={themeImage}>
      <DateCard>
        <div>{date.toLocaleString('en', { month: 'short' }).toUpperCase()}</div>
        <div>{date.toLocaleString('en', { day: 'numeric' })}</div>
      </DateCard>
    </DateCardBackground>
    <Title>{themeOfTheDay}</Title>
    <Designer>
      <Trans i18nKey="payment.designer" values={{ designer: fullName }} />
    </Designer>
    <LinkSwitch to={changeLink}>
      <IconSwitch />
    </LinkSwitch>
  </PlanCard>
);

Plan.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  changeLink: PropTypes.string.isRequired,
  plan: PropTypes.shape({
    themeOfTheDay: PropTypes.string,
    themeImage: PropTypes.string,
    tridDesigner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
};

const PlanCard = styled.div`
  & {
    overflow: hidden;
    width: 100%;
    margin: 20px 0;
    display: grid;
    grid-template-areas:
      'date title change'
      'date designer change';
    grid-template-columns: max-content 1fr max-content;
    column-gap: 12px;
    row-gap: 2px;
    align-items: center;
  }
`;

const DateCard = styled.div`
  & {
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 4px;
    background: transparent;
    backdrop-filter: blur(14px);
    > div {
      font-size: 15px;
      color: var(--white);
    }

    > div:first-child {
      font-size: 13px;
      color: var(--white);
      font-family: 'Airbnb Cereal App Book' !important;
    }
  }
`;

const DateCardBackground = styled.div`
  & {
    grid-area: date;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    ${({ url }) => (url ? `background: url(${url})` : 'background-color: var(--dark-blue-grey)')};
    background-size: cover;
    border-radius: 4px;
  }
`;

const Title = styled.div`
  & {
    grid-area: title;
    font-size: 15px;
    line-height: 1.6;
    color: var(--dark-blue-grey);
    align-self: flex-end;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Designer = styled.div`
  & {
    grid-area: designer;
    font-size: 12px;
    line-height: 1.5;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
    align-self: flex-start;
  }
`;

const LinkSwitch = styled(Link)`
  & {
    grid-area: change;
    background: none;
    border: none;
    text-decoration: none;
    padding: 0;
    height: fit-content;
    align-self: center;
  }
`;
