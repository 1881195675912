import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { IconPin } from './IconPin';

/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

export const Event = ({
  name, time, reason, district, isLast, type, onClick,
}) => (
  <Grid>
    <Time>{time}</Time>
    <Name>{name}</Name>
    {!isLast && <Line />}
    <EventCard isLast={isLast} type={type} onClick={onClick}>
      <ReasonTitle className="event-card">
        <Trans i18nKey="planDetail.reasonTitle" />
      </ReasonTitle>
      <Reason>{reason}</Reason>
      <Location>
        <IconPin />
        <div>{district}</div>
      </Location>
      <Background />
    </EventCard>
  </Grid>
);

Event.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string.isRequired,
  reason: PropTypes.string,
  district: PropTypes.string,
  isLast: PropTypes.bool,
  type: PropTypes.number,
  onClick: PropTypes.func,
};

Event.defaultProps = {
  name: '',
  reason: '',
  district: '',
  isLast: false,
  type: 1,
  onClick: undefined,
};

const Grid = styled.div`
  & {
    display: grid;
    grid-template-areas:
      'time name'
      'line reason';

    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content max-content;
    column-gap: 12px;
    row-gap: 4px;
  }
`;

const Time = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: time;
    font-size: 12px;
    font-family: 'Airbnb Cereal App Bold' !important;
    color: var(--dark-blue-grey);
  }
`;

const Name = styled.div`
  & {
    grid-area: name;
    font-size: 15px;
    line-height: 1.6;
    color: var(--dark-blue-grey);
  }
`;

const Line = styled.div`
  & {
    justify-self: center;
    grid-area: line;
    width: 1px;
    background: #c0ccda;
  }
`;

const EventCard = styled.div`
  & {
    position: relative;
    ${({ isLast }) => !isLast && 'margin-bottom: 48px'};
    opacity: 0.8;
    filter: alpha(opacity=80);
    padding: 12px 16px;
    grid-area: reason;
    background: url(${({ type }) => require(`assets/eventType${type}.png`)});
    border-radius: 4px;
    background-size: cover;
    overflow: hidden;
  }
`;

const Background = styled.div`
  & {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-70);
  }
`;

const ReasonTitle = styled.div`
  & {
    font-size: 15px;
    line-height: 1.6;
    color: var(--white);
  }
`;

const Reason = styled.div`
  & {
    margin-bottom: 14px;
    font-size: 13px;
    line-height: 1.54;
    color: var(--white);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const Location = styled.div`
  & {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.33;
    color: var(--white);
    > div {
      font-family: 'Airbnb Cereal App Book' !important;
    }
  }
`;
