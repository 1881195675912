import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { throttle } from 'lodash';

import { IconSupport } from './IconSupport';
import { IconRightArrow } from './IconRightArrow';
import { Image } from './Image';

export const Header = ({ url }) => {
  const [{ isScrolling }, setState] = useState({
    scrollPos: window.pageYOffset,
    isScrolling: false,
  });
  useEffect(() => {
    const checkScroll = throttle(() => {
      const newPos = window.pageYOffset;
      setState(({ scrollPos }) => ({
        scrollPos: newPos,
        isScrolling: Math.abs(scrollPos - newPos) < 10 ? isScrolling : scrollPos < newPos,
      }));
    }, 500);
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, [setState]);

  return (
    <OuterLayout isScrolling={isScrolling}>
      <InnerLayout>
        <HeaderLeftSide>
          <LinkBack to={url}>
            <IconRightArrow />
          </LinkBack>
        </HeaderLeftSide>
        <LogoImage alt="Tripster" fileName="logo" />
        <HeaderRightSide>
          <SupportLink href="https://wa.me/905446373611" rel="noopener noreferrer" target="_blank">
            <SupportButton id="traveller-offer-live-support-button">
              <IconSupport />
              <div>
                <Trans i18nKey="header.support" />
              </div>
            </SupportButton>
          </SupportLink>
        </HeaderRightSide>
      </InnerLayout>
    </OuterLayout>
  );
};

Header.propTypes = {
  url: PropTypes.string,
};

Header.defaultProps = {
  url: undefined,
};

const OuterLayout = styled.div`
  & {
    position: sticky;
    top: ${({ isScrolling }) => (isScrolling ? 'calc( - 1 * var(--header-height))' : '0')};
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: stretch;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
    z-index: 50;
    transition: top 1s ease-in-out;

    @media ${({ theme }) => theme.device.web} {
      top: 0;
    }
  }
`;

const InnerLayout = styled.div`
  & {
    width: 100%;
    max-width: 964px;
    display: flex;
    align-items: center;
  }
`;

const LogoImage = styled(Image)`
  & {
    @media ${({ theme }) => theme.device.web} {
      margin: 0 24px;
    }
    width: 148px;
  }
`;

const LinkBack = styled(Link)`
  & {
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;

    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const HeaderSide = styled.div`
  & {
    display: grid;
    align-items: stretch;
    margin: 0 24px;
    flex: 1;
  }
`;

const HeaderLeftSide = styled(HeaderSide)`
  & {
    margin-left: 24px;
    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const HeaderRightSide = styled(HeaderSide)`
  & {
    margin-right: 24px;
  }
`;

const SupportLink = styled.a`
  & {
    justify-self: flex-end;
    text-decoration: none;
  }
`;

const SupportButton = styled(Button)`
  && {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 4px;
    border: solid 1px var(--very-light-blue);
    padding: 0;

    div {
      display: none;
    }

    @media ${({ theme }) => theme.device.web} {
      padding: 0 0 0 20px;
      width: 180px;

      div {
        display: initial;
        width: 100%;
      }
    }
  }
`;
