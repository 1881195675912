import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import ReactJoyride from 'react-joyride';

import {
  ErrorDialog, Header, IconChange, IconRightArrow, IconSwitch, Loading, PlanSummary, Event,
} from 'components';
import { useFetchBasket, useTheme } from 'hooks';
import {
  getPlanChangeUrl, getPlanUrl, getPaymentUrl, getDesignerUrl, getWelcomeUrl,
} from 'navigation';
import sinatra from 'assets/sinatra.jpg';
import step1 from 'assets/tutorial-step1.png';
import step2 from 'assets/tutorial-step2.png';
import step3 from 'assets/tutorial-step3.png';
import step4 from 'assets/tutorial-step4.png';
import { storage } from 'adapters';
import { TutorialDialog } from 'components/TutorialDialog';

const isSameDate = (a, b) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

export const PlanDetail = () => {
  const { state: { planChange } = {} } = useLocation();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setTick] = useState(0);

  const [isPlanChangeModalOpen, setIsPlanChangeModalOpen] = useState(Boolean(planChange));
  const {
    device: { web },
  } = useTheme();
  const isWeb = useMediaQuery(web);

  const { guid, date } = useParams();
  const {
    data: {
      name, basketResult = [], basketSource = [], planDays,
    } = {}, isLoading, getActivePlan,
  } = useFetchBasket();

  if (isLoading) {
    return <Loading />;
  }

  const currentDate = new Date(date);
  const indexOfDate = planDays.findIndex((value) => isSameDate(value, currentDate));
  const percentage = ((indexOfDate + 1) / planDays.length) * 100;

  const {
    tridDesigner: {
      fullName, image, tdRating, tdRatingCount,
    },
    themeOfTheDay,
    shortDescription,
    difficulty,
    planAvaregePrice,
    walking,
    cards,
    themeImage,
    id,
  } = getActivePlan({ date: currentDate, basketResult, basketSource }) || {
    tridDesigner: {
      fullName: name,
      image: sinatra,
      tdRating: 5,
      tdRatingCount: null,
    },
    ...t('planDetail.noPlan', { returnObjects: true }),
  };

  const handleTutorialFinish = () => {
    storage.set('tutorial:plan-detail', true);
    setTick((val) => val + 1);
  };

  return (
    <>
      {isWeb ? (
        <ReactJoyride
          run={!storage.get('tutorial:plan-detail')}
          callback={({ action }) => {
            if (action === 'reset') {
              storage.set('tutorial:plan-detail', true);
            }
          }}
          continuous
          steps={t('planDetail.tutorial', { returnObjects: true })} />
      ) : (
        <TutorialDialog images={[step1, step2, step3, step4]} onFinish={handleTutorialFinish} isOpen={!storage.get('tutorial:plan-detail')} />
      )}

      <Header url={indexOfDate === 0 ? getWelcomeUrl({ guid }) : getPlanUrl({ guid, date: planDays[indexOfDate - 1] })} />
      <Content>
        <CoverImage background={themeImage ? `url(${themeImage})` : 'var(--seafoam-blue)'} />
        {!id && (
          <NoPlanCover>
            <Trans i18nKey="planDetail.noPlan.cover" />
          </NoPlanCover>
        )}
        <PlanSummary
          designerUrl={id && getDesignerUrl({ planId: id, guid })}
          date={currentDate}
          title={themeOfTheDay}
          description={shortDescription}
          planAvaregePrice={planAvaregePrice}
          planCurrency="₺"
          difficulty={difficulty}
          walking={walking}
          designer={{
            image,
            name: fullName,
            rating: tdRating,
            ratingCount: tdRatingCount,
          }}>
          <ChangePlansLink to={getPlanChangeUrl({ guid, date: currentDate })}>
            <ChangePlansButton id="traveller-offer-change-plans-button">
              <IconChange />
              <ChangePlansText>
                <Trans i18nKey="planDetail.changePlans" />
              </ChangePlansText>
            </ChangePlansButton>
          </ChangePlansLink>
        </PlanSummary>
        <Events>
          <Title>
            <Trans i18nKey="planDetail.title" />
          </Title>
          {cards.map(({
            startTime, nameOfTheEvent, reason, eventType, discrict,
          }, i) => (
            <Event
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              district={discrict}
              name={nameOfTheEvent}
              reason={reason}
              time={startTime}
              type={eventType}
              isLast={i === cards.length - 1}
              onClick={() => setIsModalOpen(true)} />
          ))}
        </Events>
      </Content>
      <FooterLayout>
        <FooterInnerLayout>
          <LinkSwitch to={getPlanChangeUrl({ guid, date: currentDate })}>
            <IconSwitch />
          </LinkSwitch>
          <FooterLinkBack to={indexOfDate === 0 ? getWelcomeUrl({ guid }) : getPlanUrl({ guid, date: planDays[indexOfDate - 1] })}>
            <IconRightArrow />
          </FooterLinkBack>
          <FooterContent>
            <ProgressBar>
              <TooltipArrow percentage={percentage} />
              <LabelDate percentage={percentage}>{currentDate.toLocaleString('en', { day: 'numeric', month: 'short' })}</LabelDate>
              <ProgressBarInner percentage={percentage} />
            </ProgressBar>
          </FooterContent>
          <LinkNext
            to={indexOfDate === planDays.length - 1 ? getPaymentUrl({ guid }) : getPlanUrl({ guid, date: new Date(planDays[indexOfDate + 1]) })}>
            <NextButton id="traveller-offer-next-button">
              <Trans i18nKey={indexOfDate === planDays.length - 1 ? 'planDetail.review' : 'planDetail.next'} />
            </NextButton>
          </LinkNext>
        </FooterInnerLayout>
      </FooterLayout>
      <ErrorDialog
        title={t('planDetail.error.title')}
        error={t('planDetail.error.message')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} />
      <Dialog open={isPlanChangeModalOpen} onClose={() => setIsPlanChangeModalOpen(false)}>
        <DialogContent>
          <DialogMessage>
            <Trans i18nKey="planDetail.planChangeDialog.message" />
          </DialogMessage>
          <DialogButton onClick={() => setIsPlanChangeModalOpen(false)}>
            <Trans i18nKey="planDetail.planChangeDialog.button" />
          </DialogButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Content = styled.div`
  & {
    position: relative;
    padding: 0 24px;
    box-sizing: border-box;
    column-gap: 110px;
    display: grid;
    grid-template-areas:
      'cover'
      'summary'
      'events';

    @media ${({ theme }) => theme.device.web} {
      grid-template-areas:
        'cover cover'
        'events summary';
      row-gap: 40px;
    }
  }
`;

const CoverImage = styled.div`
  & {
    margin: 0 -24px;
    grid-area: cover;
    height: 198px;
    width: calc(100% + 48px);
    background: ${({ background }) => background};
    background-size: cover;
    @media ${({ theme }) => theme.device.web} {
      height: 320px;
    }
  }
`;

const NoPlanCover = styled.div`
  & {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    display: flex;
    width: 100%;
    height: 198px;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    color: var(--white);
    @media ${({ theme }) => theme.device.web} {
      height: 320px;
      font-size: 60px;
    }
  }
`;

const FooterLayout = styled.div`
  & {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: sticky;
    bottom: 0;
    background: var(--white);
    z-index: 50;

    @media ${({ theme }) => theme.device.web} {
      border-radius: 4px;
      box-shadow: 0 -1px 0 0 var(--very-light-blue);
    }
  }
`;

const FooterInnerLayout = styled.div`
  & {
    width: 100%;
    max-width: 916px;
    display: flex;
    align-items: stretch;

    @media ${({ theme }) => theme.device.web} {
      align-items: center;
    }
  }
`;

const FooterContent = styled.div`
  & {
    flex: 1;
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 50px;
    justify-content: flex-end;
  }
`;

const ProgressBar = styled.div`
  & {
    width: 100%;
    height: 7px;
    border-radius: 3.5px;
    background-color: var(--very-light-blue);
    margin: 0;
    position: relative;

    @media ${({ theme }) => theme.device.web} {
      height: 14px;
      border-radius: 9.5px;
    }
  }
`;

const ProgressBarInner = styled.div`
  & {
    width: ${({ percentage }) => percentage}%;
    height: 7px;
    border-radius: 3.5px;
    background-color: var(--dark-blue-grey);
    transition: width 1s ease-in-out;

    @media ${({ theme }) => theme.device.web} {
      height: 14px;
      border-radius: 9.5px;
    }
  }
`;

const LinkSwitch = styled(Link)`
  & {
    background: none;
    border: none;
    text-decoration: none;
    padding: 0;
    margin-left: 10px;
    height: fit-content;
    align-self: center;
    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const FooterLinkBack = styled(Link)`
  & {
    display: none;

    @media ${({ theme }) => theme.device.web} {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-color: var(--pale-grey);
      border: none;
      padding: 0;
    }
  }
`;

const LinkNext = styled(Link)`
  & {
    text-decoration: none;
  }
`;

const NextButton = styled(Button)`
  && {
    width: 105px;
    height: 100%;
    border-radius: 0;
    background-color: var(--dark-blue-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--white);
    font-size: 15px;

    :hover {
      background-color: var(--rhino);

      @media (hover: none) {
        background-color: var(--dark-blue-grey);
        color: var(--white);
      }
    }

    @media ${({ theme }) => theme.device.web} {
      width: 205px;
      height: 50px;
      border-radius: 4px;
    }
  }
`;

const LabelDate = styled.div`
  & {
    position: absolute;
    left: min(${({ percentage }) => percentage}% - 18px, 100% - 50px);
    top: -39px;
    width: 50px;
    box-sizing: border-box;
    padding: 8px 0;
    border-radius: 3px;
    background-color: var(--dark-blue-grey);
    font-size: 12px;
    text-align: center;
    color: var(--white);
    transition: left 1s ease-in-out;
  }
`;

const TooltipArrow = styled.div`
  & {
    position: absolute;
    left: min(${({ percentage }) => percentage}% - 6px, 100% - 15px);
    top: -12px;
    width: 9px;
    height: 9px;
    transform: rotate(-315deg);
    border-radius: 1px;
    background-color: var(--dark-blue-grey);
    transition: left 1s ease-in-out;
  }
`;

const Events = styled.div`
  & {
    padding: 0;
    margin-bottom: 75px;
    grid-area: events;
    @media ${({ theme }) => theme.device.web} {
      max-width: 540px;
      justify-self: flex-end;
    }
  }
`;

const Title = styled.h1`
  & {
    margin: 0 0 10px 0;
    font-size: 22px;
    font-weight: normal;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.45;
    color: var(--dark-blue-grey);
  }
`;

const ChangePlansLink = styled(Link)`
  & {
    display: none;

    @media ${({ theme }) => theme.device.web} {
      display: initial;
      text-decoration: none;
    }
  }
`;

const ChangePlansButton = styled(Button)`
  && {
    width: 100%;
    border-radius: 4px;
    background-color: var(--pale-grey);
    padding: 13px;
    border: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-blue-grey);
  }
`;

const ChangePlansText = styled.div`
  & {
    margin-left: 12px;
  }
`;

const DialogButton = styled(Button)`
  && {
    padding: 13px 0;
    width: 100%;
    border-radius: 4px;
    background-color: var(--dark-blue-grey);
    border: none;
    line-height: 1.6;
    font-size: 15px;
    color: var(--white);

    :hover {
      background-color: var(--rhino);
    }
  }
`;

const DialogMessage = styled.div`
  & {
    margin: 6px 0 22px 0;
    width: 100%;
    font-size: 16px;
    line-height: 1.44;
    text-align: center;
    color: var(--dark-blue-grey);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const DialogContent = styled.div`
  & {
    padding: 32px;
    max-width: 426px;
    box-sizing: border-box;

    @media ${({ theme }) => theme.device.web} {
      padding: 64px;
    }
  }
`;
