import { useState } from 'react';

import { config, storage } from 'adapters';

export const useShare = () => {
  const [state, setState] = useState({});

  const share = async ({
    guid, body, onComplete = () => {}, onError = () => {},
  }) => {
    try {
      const tokenCacheKey = `api-fetch:token:${guid}`;
      let sessionToken = storage.get(tokenCacheKey);
      if (!sessionToken) {
        const authResponse = await fetch(`${config.API_URL}/Users/authenticate/${guid}`);
        const { token } = await authResponse.json();
        storage.set(tokenCacheKey, token);
        sessionToken = token;
      }
      setState({ isLoading: true });
      const response = await fetch(`${config.API_URL}/UserBasket/planshare/${guid}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        onComplete();
        setState({ isLoading: false, isFetched: true });
      } else {
        const error = await response.text();
        onError(error);
        setState({ isLoading: false, error });
      }
    } catch (error) {
      onError(error);
      setState({ isLoading: false, error });
    }
  };
  return {
    ...state,
    share,
  };
};
