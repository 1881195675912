import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconEffort = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21"
    viewBox="0 0 22 21">
    <path
      fill="none"
      fillRule="nonzero"
      stroke="#1B2D41"
      strokeWidth="1.243"
      d="M15.617 1c.26 0 .477.214.477.47v7.78c.087.064.152.107.196.192l2.931 4.36c.044.064.065.128.087.192l1.65 4.98c.174.492-.217 1.005-.738 1.026H2.61c-.564 0-.955-.598-.694-1.111l.478-.983a.854.854 0 0 1 .499-.407l3.583-.983a.782.782 0 0 0 .39-.256l1.368-1.689a.782.782 0 0 1 .391-.256l3.3-1.005a.789.789 0 0 0 .413-.277l2.67-3.612a.503.503 0 0 1 .153-.15V4.975h-2.953a.442.442 0 0 1-.456-.449v-2.35c0-.257.195-.45.456-.45h2.931V1.47c0-.256.217-.47.478-.47zM2.66 6.309C4.29 5.202 4.592 5.12 4.812 5.13l.052.004 1.178.065c.368.022.619.13 1.856 2.108a.325.325 0 0 0 .193.13l1.45.218c.405.065.676.478.618.913a.835.835 0 0 1-.367.608.785.785 0 0 1-.445.087l-1.45-.217c-.482-.065-.908-.37-1.178-.804l-.116-.174-.387 2.042 1.044.196c.56.087 1.005.565 1.14 1.195l.367 1.782-.676.24c-.29.108-.541.303-.734.564l-.097.13-.445-2.107-.966-.174-.792 4.194-1.797.565.985-5.063a1.44 1.44 0 0 1-.87-.652c-.212-.348-.29-.804-.212-1.217l.425-2.151c-.058.043-.097.065-.155.108a1.095 1.095 0 0 0-.328.413L2.35 9.786c-.174.39-.618.543-.966.347-.348-.217-.483-.695-.31-1.086l.716-1.695c.193-.435.502-.804.87-1.043zm3.5-5.003l.149.009c.949.094 1.643.95 1.55 1.914a1.734 1.734 0 0 1-1.886 1.574c-.95-.094-1.643-.951-1.55-1.915a1.734 1.734 0 0 1 1.886-1.573z" />
  </svg>
);

IconEffort.propTypes = {
  className: PropTypes.string,
};

IconEffort.defaultProps = {
  className: undefined,
};
