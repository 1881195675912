import React from 'react';

/* eslint-disable max-len */
export const IconHeart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#1B2D41"
      fillRule="evenodd"
      d="M17.157 7.813a4.062 4.062 0 0 1-1.19 2.873l-5.525 5.525a.625.625 0 0 1-.884 0l-5.525-5.525A4.063 4.063 0 0 1 9.78 4.939l.221.22.22-.22a4.062 4.062 0 0 1 6.937 2.873zM10 14.884l4.42-4.42.663-.663a2.813 2.813 0 1 0-3.979-3.979l-.662.663a.625.625 0 0 1-.884 0l-.662-.663a2.813 2.813 0 0 0-3.98 3.979L10 14.885z" />
  </svg>
);
