import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconPin = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M9.44 14.159a16.4 16.4 0 0 0 1.683-1.538c1.492-1.575 2.377-3.197 2.377-4.746a4.5 4.5 0 1 0-9 0c0 1.549.885 3.17 2.377 4.746A16.4 16.4 0 0 0 9 14.497c.136-.1.284-.214.44-.338zm2.5-.764a17.512 17.512 0 0 1-2.628 2.26.562.562 0 0 1-.624 0 15.985 15.985 0 0 1-.83-.617 17.512 17.512 0 0 1-1.798-1.643C4.39 11.63 3.375 9.772 3.375 7.875a5.625 5.625 0 0 1 11.25 0c0 1.897-1.014 3.755-2.685 5.52z" />
      <path d="M9 10.125a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5zM9 9a1.125 1.125 0 1 0 0-2.25A1.125 1.125 0 0 0 9 9z" />
    </g>
  </svg>
);

IconPin.propTypes = {
  className: PropTypes.string,
};

IconPin.defaultProps = {
  className: undefined,
};
