import { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line import/no-internal-modules
import './font/style.css';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Airbnb Cereal App Medium' !important;
    margin: 0;
    padding: 0;
    background: var(--white);
    overflow-x: hidden;
  }
  
  div#root {
    width: 100%;
  }
  
  body * {
    font-family: 'Airbnb Cereal App Medium' !important;
  }
  
  .react-joyride__tooltip {
    >div:first-child {
      >h4 {
        color: var(--dark-blue-grey);
      }

      > div {
        color: var(--slate);
        font-family: 'Airbnb Cereal App Book' !important;
      }
    }

    button[data-action = "back"] {
      color: var(--slate) !important;
    }
    
    button[data-action = "primary"] {
      background-color: var(--dark-blue-grey) !important;
    }
  }
  
  div[id^=react-joyride-step-] {
    > span {
      z-index: 10 !important;
    }
  }
  
  .MuiButton-label {
    text-transform: none;
  }
  
  :root {
    --dark-blue-grey: #1b2d41;
    --slate: #3c4857;
    --white: #ffffff;
    --dark-blue-grey-7: rgba(27, 45, 65, 0.07);
    --very-light-blue: #e5e9f2;
    --black-0: rgba(0, 0, 0, 0);
    --black-76: rgba(0, 0, 0, 0.76);
    --blue-grey: #8292a5;
    --light-blue-grey: #c0ccda;
    --pale-grey: #f6f6f7;
    --light-periwinkle: #d3d3df;
    --yellow-orange: #ffb300;
    --pale: #fff5dc;
    --red-pink: #f53158;
    --green: #1eab31;
    --very-light-pink: #efefef;
    --pinkish-grey: #b2b0b0;
    --rhino: #26405c;
    --dark-blue-grey-13: rgba(27, 45, 65, 0.13);
    --dark-blue-grey-20: rgba(27, 45, 65, 0.20);
    --seafoam-blue: #7ebed4;
    --regent-gray: #8292a5;
    --green-9: rgba(30, 171, 49, 0.09);
    --green-28: rgba(30, 171, 49, 0.28);
    --black-70: rgba(0, 0, 0, 0.7);
    --header-height: 60px;
}
  
  button:focus {
    outline: none;
  }
`;
