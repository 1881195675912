import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconUnlock = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24">
    <g fill="#1EAB31" fillRule="evenodd">
      <path d="M3 13a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1H3zm0-2h14a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3z" />
      <path d="M6 12a1 1 0 0 1-2 0V6a6 6 0 1 1 12 0v1a1 1 0 0 1-2 0V6a4 4 0 1 0-8 0v6z" />
    </g>
  </svg>
);

IconUnlock.propTypes = {
  className: PropTypes.string,
};

IconUnlock.defaultProps = {
  className: undefined,
};
