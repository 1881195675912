import React from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

export const ErrorDialog = ({
  title, error, isOpen, onClose,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogContent>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogMessage>{error}</DialogMessage>
      <DialogButton onClick={onClose}>
        <Trans i18nKey="errorDialog.ok" />
      </DialogButton>
    </DialogContent>
  </Dialog>
);

ErrorDialog.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ErrorDialog.defaultProps = {
  title: null,
  error: null,
  isOpen: false,
  onClose: undefined,
};

const DialogButton = styled(Button)`
  && {
    padding: 13px 0;
    width: 100%;
    border-radius: 4px;
    background-color: var(--dark-blue-grey);
    border: none;
    line-height: 1.6;
    font-size: 15px;
    color: var(--white);

    :hover {
      background-color: var(--rhino);
    }
  }
`;

const DialogMessage = styled.div`
  & {
    margin: 6px 0 22px 0;
    width: 100%;
    font-size: 16px;
    line-height: 1.44;
    text-align: center;
    color: var(--dark-blue-grey);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const DialogTitle = styled.div`
  & {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1.44;
    text-align: center;
    color: var(--dark-blue-grey);
  }
`;

const DialogContent = styled.div`
  & {
    padding: 32px;
    max-width: 426px;
    box-sizing: border-box;

    @media ${({ theme }) => theme.device.web} {
      padding: 64px;
    }
  }
`;
