import React from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router';

import { Loading, Main } from 'components';
import { useFetchBasket } from 'hooks';
import { getPlanUrl } from 'navigation';

const getMonth = (value) => value.toLocaleString('en', { month: 'long' });

export const Welcome = () => {
  const { guid } = useParams();
  const { data: { name, planDays = [] } = {}, isLoading } = useFetchBasket();

  if (isLoading) {
    return <Loading />;
  }

  const minDate = new Date(Math.min(...planDays));
  const maxDate = new Date(Math.max(...planDays));
  const dateRange = minDate.getMonth() === maxDate.getMonth()
    ? `${minDate.getDate()}-${maxDate.getDate()} ${getMonth(maxDate)}`
    : `${minDate.getDate()} ${getMonth(minDate)}-${maxDate.getDate()} ${getMonth(maxDate)}`;

  return (
    <Main
      title={<Trans i18nKey="welcome.title" values={{ name }} />}
      text={<Trans i18nKey="welcome.text" values={{ dateRange }} />}
      link={<Trans i18nKey="welcome.discoverLink" />}
      url={getPlanUrl({ guid, date: minDate })} />
  );
};
