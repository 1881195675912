import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconSuccess = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50">
    <g fill="none" fillRule="evenodd">
      <circle cx="25" cy="25" r="25" fill="#1EAB31" />
      <path
        fill="#FFF"
        d="M20.313 30.603L36.395 14.52a1.562 1.562 0 1 1 2.21 2.21L21.417 33.917c-.61.61-1.6.61-2.21 0l-7.812-7.812a1.562 1.562 0 1 1 2.21-2.21l6.707 6.708z" />
    </g>
  </svg>
);

IconSuccess.propTypes = {
  className: PropTypes.string,
};

IconSuccess.defaultProps = {
  className: undefined,
};
