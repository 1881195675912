import React from 'react';

/* eslint-disable max-len */
export const IconBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20">
    <path
      fill="#1B2D41"
      fillRule="evenodd"
      d="M3.919 10l6.947 6.947a1.313 1.313 0 0 1-1.857 1.856l-7.875-7.875a1.313 1.313 0 0 1 0-1.856L9.01 1.197a1.313 1.313 0 0 1 1.857 1.856L3.919 10z" />
  </svg>
);
