import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconClose = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32">
    <g fill="#1B2D41" fillRule="evenodd">
      <path d="M21.293 9.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 1 1-1.414-1.414l12-12z" />
      <path d="M9.293 10.707a1 1 0 1 1 1.414-1.414l12 12a1 1 0 0 1-1.414 1.414l-12-12z" />
    </g>
  </svg>
);

IconClose.propTypes = {
  className: PropTypes.string,
};

IconClose.defaultProps = {
  className: undefined,
};
