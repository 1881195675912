import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconCalendar = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <g fill="#1B2D41" fillRule="evenodd">
      <path d="M6.75 6.75A.75.75 0 0 0 6 7.5V18c0 .414.336.75.75.75h10.5A.75.75 0 0 0 18 18V7.5a.75.75 0 0 0-.75-.75H6.75zm0-1.5h10.5A2.25 2.25 0 0 1 19.5 7.5V18a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 18V7.5a2.25 2.25 0 0 1 2.25-2.25z" />
      <path d="M14.25 4.5a.75.75 0 1 1 1.5 0v3a.75.75 0 1 1-1.5 0v-3zM8.25 4.5a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-1.5 0v-3zM5.25 11.25a.75.75 0 0 1 0-1.5h13.5a.75.75 0 1 1 0 1.5H5.25z" />
    </g>
  </svg>
);

IconCalendar.propTypes = {
  className: PropTypes.string,
};

IconCalendar.defaultProps = {
  className: undefined,
};
