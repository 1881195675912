import { Trans } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

export const EventsSummary = ({
  events, maxCount, rowMoreClassName, onMoreClick,
}) => (
  <>
    {events.slice(0, maxCount).map((event, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Event key={i}>
        <EventTime>{event.startTime}</EventTime>
        <EventName>{event.nameOfTheEvent}</EventName>
        {i !== events.length - 1 && <EventLine />}
      </Event>
    ))}
    {events.length > maxCount && (
      <EventMore className={rowMoreClassName} onClick={onMoreClick}>
        <EventMoreIcon>
          <Dot />
          <Dot />
          <Dot />
        </EventMoreIcon>
        <EventName>
          <Trans i18nKey="planChange.more" values={{ count: events.length - maxCount }} />
        </EventName>
      </EventMore>
    )}
  </>
);

EventsSummary.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      nameOfTheEvent: PropTypes.string,
    }),
  ),
  maxCount: PropTypes.number,
  rowMoreClassName: PropTypes.string,
  onMoreClick: PropTypes.func,
};

EventsSummary.defaultProps = {
  events: [],
  maxCount: 3,
  rowMoreClassName: undefined,
  onMoreClick: undefined,
};

const Event = styled.div`
  & {
    display: grid;
    grid-template-areas:
      'time name'
      'line reason';
    column-gap: 12px;
    row-gap: 3px;
    grid-template-columns: 36px 1fr;
  }
`;

const EventTime = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: time;
    font-size: 12px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.33;
    color: var(--dark-blue-grey);
  }
`;

const EventName = styled.div`
  & {
    grid-area: name;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const EventLine = styled.div`
  & {
    justify-self: center;
    grid-area: line;
    width: 1px;
    height: 15px;
    background: var(--light-blue-grey);
  }
`;

const EventMore = styled(Event)`
  & {
    margin-top: 6px;
    ${({ onClick }) => onClick && 'cursor: pointer;'}
  }
`;

const EventMoreIcon = styled.div`
  & {
    grid-area: time;
    width: 24px;
    height: 24px;
    border: solid 1px var(--light-blue-grey);
    border-radius: 50%;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Dot = styled.div`
  & {
    width: 2px;
    height: 2px;
    border: solid 1px var(--light-blue-grey);
    border-radius: 50%;
    justify-self: center;
    margin: 1px;
  }
`;
