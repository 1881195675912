import React from 'react';

/* eslint-disable max-len */
export const IconRightArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
    <g fill="#1B2D41" fillRule="evenodd">
      <path d="M24 15a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2h16z" />
      <path d="M9.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 1 1 1.414 1.414L9.414 16z" />
    </g>
  </svg>
);
