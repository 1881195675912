import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { config, storage } from 'adapters';
import * as PropTypes from 'prop-types';

export const BasketContext = createContext({});

const customFetch = async ({
  guid, signal, onComplete, onError,
}) => {
  try {
    const tokenCacheKey = `api-fetch:token:${guid}`;
    let sessionToken = storage.get(tokenCacheKey);
    if (!sessionToken) {
      const authResponse = await fetch(`${config.API_URL}/Users/authenticate/${guid}`, { signal });
      const { token } = await authResponse.json();
      storage.set(tokenCacheKey, token);
      sessionToken = token;
    }
    const response = await fetch(`${config.API_URL}/UserBasket/getUserBasket/${guid}`, {
      signal,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    onComplete(await response.json());
  } catch (e) {
    onError(e);
  }
};

const mapBasket = (data) => {
  if (!data) {
    return undefined;
  }
  const { planDays, basketResult, ...rest } = data;
  return {
    planDays: planDays.map((val) => new Date(`${val}.000Z`)),
    basketResult: basketResult
      .filter(({ selectedDate }) => selectedDate)
      .map(({ selectedDate, ...item }) => ({
        selectedDate: new Date(`${selectedDate}.000Z`),
        ...item,
      }))
      .sort((a, b) => a.selectedDate - b.selectedDate),
    ...rest,
  };
};

const isSameDate = (a, b) => a.getFullYear() === b.getFullYear()
  && a.getMonth() === b.getMonth()
  && a.getDate() === b.getDate();

const getActivePlan = ({ date, basketSource, basketResult }) => {
  const basketFinder = ({ selectedDate }) => isSameDate(selectedDate, date);
  const { planId } = basketResult.find(basketFinder) || {};

  return basketSource.find(({ id }) => id === planId);
};

const getPlanDay = ({ basketResult, planId }) => {
  const { selectedDate } = basketResult.find((item) => item.planId === planId) || {};
  return selectedDate;
};

export const BasketContextProvider = ({ children }) => {
  const { guid } = useParams();
  const cacheKey = `api-fetch:basket:${guid}`;
  const [state, setState] = useState({
    isLoading: !storage.get(cacheKey),
    data: mapBasket(storage.get(cacheKey)),
  });

  useEffect(() => {
    if (!storage.get(cacheKey)) {
      const abortController = new AbortController();
      customFetch({
        guid,
        signal: abortController.signal,
        onComplete: (data) => {
          storage.set(`api-fetch:basket:${guid}`, data);
          setState({
            isLoading: false,
            data: mapBasket(data),
          });
        },
        onError: (error) => {
          if (error.name !== 'AbortError') {
            setState({
              isLoading: false,
              error,
            });
          }
        },
      });
      return () => abortController.abort();
    }
    return () => {};
  }, [guid]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BasketContext.Provider
      value={{
        ...state,
        getActivePlan,
        getPlanDay,
        update: (data) => {
          const oldData = storage.get(cacheKey);
          storage.set(cacheKey, { ...oldData, ...data });
          setState({
            isLoading: false,
            data: mapBasket({ ...oldData, ...data }),
          });
        },
      }}>
      {children}
    </BasketContext.Provider>
  );
};

BasketContextProvider.propTypes = {
  children: PropTypes.node,
};

BasketContextProvider.defaultProps = {
  children: null,
};
