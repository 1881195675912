import React from 'react';
import { Trans } from 'react-i18next';

import { Main } from 'components';

export const NotFound = () => (
  <Main
    title={<Trans i18nKey="notFound.title" />}
    text={<Trans i18nKey="notFound.text" />}
    link={<Trans i18nKey="notFound.link" />}
    useNativeLink
    url="mailto:info@heytripster.com" />
);
