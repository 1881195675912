import React, { Suspense } from 'react';
import * as PropTypes from 'prop-types';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';

import { config } from 'adapters';
import { GlobalStyle, theme } from 'style';
import { createBrowserHistory } from 'history';
import 'i18n';

const createHistory = () => {
  const history = createBrowserHistory();
  const { push } = history;
  history.push = (...args) => {
    window.scrollTo(0, 0);
    return push(...args);
  };

  return history;
};

hotjar.initialize(config.HOT_JAR_ID, config.HOT_JAR_SNIPPET_VERSION);

TagManager.initialize({
  gtmId: config.GTM_ID,
});

export const Providers = ({ children }) => (
  <Suspense fallback={null}>
    <ThemeProvider theme={theme}>
      <>
        <Router history={createHistory()}>{children}</Router>
        <GlobalStyle />
      </>
    </ThemeProvider>
  </Suspense>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};
