import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { validate } from 'luhn';

import { useFetchBasket, usePay } from 'hooks';
import { getPlanChangeUrl, getPlanUrl } from 'navigation';
import {
  IconBack, Image, IconCalendar, IconSelected, Loading, ErrorDialog, Header,
} from 'components';
import { Link } from 'react-router-dom';
import { Plan } from './components';

export const PaymentDetail = () => {
  const { guid } = useParams();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isDiscountClicked, setIsDiscountClicked] = useState(false);
  const { t } = useTranslation();
  const {
    data: {
      name, email, basketResult = [], basketSource = [], planDays = [], payment: { price, discountedPrice } = {},
    } = {},
    isLoading,
    update,
  } = useFetchBasket();
  const { handleSubmit, control, errors } = useForm({ mode: 'onBlur' });
  const {
    pay, data: { threeDSHtmlContent } = {}, isLoading: isPayLoading, reset,
  } = usePay();

  const onSubmit = ({ cardDate, cardNumber, ...data }) => {
    pay({
      input: {
        guid,
        buyerCountry: '',
        buyerCity: '',
        buyerAddress: '',
        ip: '127.0.0.1',
        cardYear: cardDate !== undefined ? cardDate.split('/')[1] : '',
        cardMonth: cardDate !== undefined ? cardDate.split('/')[0] : '',
        cardNumber: cardNumber !== undefined ? cardNumber.replace(/ /g, '') : '',
        discount: isDiscountClicked,
        ...data,
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  useEffect(() => {
    const onMessageReceived = (e) => {
      try {
        const { paymentError, paymentStatus } = JSON.parse(e.data);
        if (paymentError) {
          reset();
          setErrorMessage(paymentError);
        } else if (paymentStatus && paymentStatus !== 2) {
          update({ status: paymentStatus });
        }
      } catch (error) {
        // ignore error
      }
    };
    window.addEventListener('message', onMessageReceived);
    return () => window.removeEventListener('message', onMessageReceived);
  });

  if (isLoading) {
    return <Loading />;
  }
  const FooterPrice = isDiscountClicked ? FooterPreSalePrice : FooterPaidPrice;

  if (threeDSHtmlContent) {
    return <ThreeDSFrame title="iyzico" srcDoc={threeDSHtmlContent} />;
  }

  return (
    <>
      <Header url={getPlanUrl({ guid, date: planDays[planDays.length - 1] })} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentOuter>
          <ContentInner>
            <Title>
              <Trans i18nKey="payment.title" />
            </Title>
            <Description>
              <Trans i18nKey="payment.description" />
            </Description>
            <Plans>
              {basketResult.map(({ planId, selectedDate }) => (
                <Plan
                  key={String(planId)}
                  changeLink={getPlanChangeUrl({ guid, date: selectedDate })}
                  date={selectedDate}
                  plan={basketSource.find(({ id }) => id === planId)} />
              ))}
              {basketResult.length > 3 && <PlansScrollItem />}
            </Plans>
            <TreeCard>
              <TreeImage alt="hand holding tree" fileName="tree" />
              <TreeDescription>
                <Trans i18nKey="payment.tree" values={{ count: basketResult.length }} />
              </TreeDescription>
            </TreeCard>
            {!(discountedPrice === 0 && isDiscountClicked) && (
              <FormFields>
                <FormTitleRow>
                  <SubTitle>
                    <Trans i18nKey="payment.formTitle" />
                  </SubTitle>
                  <Image alt="visa" fileName="visa" />
                  <Image alt="master card" fileName="mastercard" />
                  <Image alt="american express" fileName="american-express" />
                </FormTitleRow>
                <Controller
                  as={(
                    <LongTextField
                      label={t('payment.labelName')}
                      error={Boolean(errors && errors.cardName)}
                      helperText={(errors && errors.cardName && errors.cardName.message) || ' '}
                      variant="outlined"
                      disabled={isPayLoading} />
                  )}
                  control={control}
                  name="cardName"
                  defaultValue=""
                  rules={{ required: t('payment.required') }} />
                <Controller
                  as={(
                    <InputMask mask="9999 9999 9999 9999" maskChar="" disabled={isPayLoading}>
                      {() => (
                        <LongTextField
                          label={t('payment.labelCard')}
                          error={Boolean(errors && errors.cardNumber)}
                          helperText={(errors && errors.cardNumber && errors.cardNumber.message) || ' '}
                          variant="outlined"
                          disabled={isPayLoading} />
                      )}
                    </InputMask>
                  )}
                  control={control}
                  name="cardNumber"
                  defaultValue=""
                  rules={{
                    required: t('payment.required'),
                    validate: (value) => validate(value) || t('payment.invalidCardNumber'),
                    minLength: {
                      value: 19,
                      message: t('payment.invalidCardNumber'),
                    },
                  }} />
                <Controller
                  as={(
                    <InputMask mask="99/99" maskChar="" disabled={isPayLoading}>
                      {() => (
                        <TextField
                          label={t('payment.labelExpire')}
                          error={Boolean(errors && errors.cardDate)}
                          helperText={(errors && errors.cardDate && errors.cardDate.message) || ' '}
                          variant="outlined"
                          disabled={isPayLoading} />
                      )}
                    </InputMask>
                  )}
                  control={control}
                  name="cardDate"
                  defaultValue=""
                  rules={{
                    required: t('payment.required'),
                    minLength: {
                      value: 5,
                      message: t('payment.invalidCardDate'),
                    },
                  }} />
                <Controller
                  as={(
                    <InputMask mask="999" maskChar="" disabled={isPayLoading}>
                      {() => (
                        <TextField
                          label={t('payment.labelCVV')}
                          error={Boolean(errors && errors.cardValidateNumber)}
                          helperText={(errors && errors.cardValidateNumber && errors.cardValidateNumber.message) || ' '}
                          variant="outlined"
                          disabled={isPayLoading} />
                      )}
                    </InputMask>
                  )}
                  control={control}
                  name="cardValidateNumber"
                  defaultValue=""
                  rules={{
                    required: t('payment.required'),
                    minLength: {
                      value: 3,
                      message: t('payment.invalidCVV'),
                    },
                  }} />
              </FormFields>
            )}
            {discountedPrice < price && (
              <>
                <SubTitle>
                  <Trans i18nKey="payment.discountTitle" />
                </SubTitle>
                <CouponCard discount={isDiscountClicked}>
                  {isDiscountClicked ? (
                    <>
                      <div>
                        <Trans i18nKey="payment.discountApplied" />
                      </div>
                      <IconSelected />
                    </>
                  ) : (
                    <>
                      <DiscountDescription>
                        <Trans
                          i18nKey="payment.discountDescription"
                          values={{
                            count: basketResult.length,
                            discount: (((price - discountedPrice) * 100) / price).toFixed(0),
                          }} />
                      </DiscountDescription>
                      <CouponButton id="traveller-offer-apply-discount-button" type="button" onClick={() => setIsDiscountClicked(true)}>
                        <Trans i18nKey="payment.discountButton" />
                      </CouponButton>
                    </>
                  )}
                </CouponCard>
              </>
            )}
            <Disclaimer>
              <Trans
                i18nKey="payment.disclaimer"
                components={[
                  <a href="https://heytripster.com/personal-data-policy/" rel="noopener noreferrer" target="_blank">
                    1
                  </a>,
                  <a href={`https://heytripster.com/distance-sales-agreement/?utm=${name}&email=${email}`} rel="noopener noreferrer" target="_blank">
                    2
                  </a>,
                ]} />
            </Disclaimer>
            <SubmitButton type="submit" disabled={isPayLoading} id="traveller-offer-confirm-and-pay-button">
              {isPayLoading ? <CircularProgress size={30} /> : <Trans i18nKey="payment.submit" />}
            </SubmitButton>
          </ContentInner>
          <ReceiptCard>
            <ReceiptTitle>
              <IconCalendar />
              <div>
                <Trans i18nKey="payment.receiptTitle" values={{ count: basketResult.length }} />
              </div>
            </ReceiptTitle>
            <Divider />
            <ReceiptItem>
              <div>
                <Trans i18nKey="payment.receiptPlan" values={{ count: basketResult.length }} />
              </div>
              <div>{`€${price.toFixed(2)}`}</div>
            </ReceiptItem>
            <ReceiptItem>
              <div>
                <Trans i18nKey="payment.receiptServiceFee" />
              </div>
              <div>€0.00</div>
            </ReceiptItem>
            <ReceiptTotal>
              <div>Total</div>
              <ReceiptTotalPrice discount={isDiscountClicked}>{`€${price.toFixed(2)}`}</ReceiptTotalPrice>
              {isDiscountClicked && <ReceiptDiscountedPrice>{`€${discountedPrice.toFixed(2)}`}</ReceiptDiscountedPrice>}
            </ReceiptTotal>
            <Divider />
            <FreeCancellationTitle>
              <Trans i18nKey="payment.freeCancellationTitle" />
            </FreeCancellationTitle>
            <FreeCancellationDescription>
              <Trans
                i18nKey="payment.freeCancellationDescription"
                components={[
                  <a href="foo" target="_blank">
                    1
                  </a>,
                ]} />
            </FreeCancellationDescription>
          </ReceiptCard>
        </ContentOuter>
        <Footer>
          <BackLink to={getPlanUrl({ guid, date: planDays[planDays.length - 1] })}>
            <IconBack />
          </BackLink>
          <FooterTitle>
            <Trans i18nKey="payment.total" />
          </FooterTitle>
          <FooterPrice>{`€${price.toFixed(2)}`}</FooterPrice>
          {isDiscountClicked && <FooterDiscountedPrice>{`€${discountedPrice.toFixed(2)}`}</FooterDiscountedPrice>}
          <FooterSubmitButton type="submit" disabled={isPayLoading} id="traveller-offer-confirm-and-pay-button">
            {isPayLoading ? <CircularProgress size={30} /> : <Trans i18nKey="payment.submit" />}
          </FooterSubmitButton>
        </Footer>
        <ErrorDialog error={String(errorMessage)} isOpen={Boolean(errorMessage)} onClose={() => setErrorMessage(false)} />
      </form>
    </>
  );
};

const ThreeDSFrame = styled.iframe`
  & {
    width: 100%;
    height: 100vh;
    border: none;
  }
`;

const ContentOuter = styled.div`
  & {
    padding: 26px 24px;
    box-sizing: border-box;
    @media ${({ theme }) => theme.device.web} {
      position: relative;
      padding: 50px 24px;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 80px;
    }
  }
`;

const ContentInner = styled.div`
  & {
    @media ${({ theme }) => theme.device.web} {
      max-width: 540px;
      justify-self: flex-end;
      margin-bottom: 50px;
    }
  }
`;

const Title = styled.div`
  & {
    font-size: 22px;
    font-family: 'Airbnb Cereal App Bold' !important;
    line-height: 1.45;
    color: var(--dark-blue-grey);

    @media ${({ theme }) => theme.device.web} {
      font-size: 30px;
      line-height: 1.33;
    }
  }
`;

const Description = styled.div`
  & {
    margin: 6px 0 26px 0;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 12px;
    letter-spacing: -0.1px;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const Plans = styled.div`
  & {
    position: relative;
    margin: -20px -8px -20px -8px;
    height: 272px;
    overflow-y: scroll;
    padding: 0 8px;

    @media ${({ theme }) => theme.device.web} {
      overflow-y: initial;
      height: auto;
    }
  }
`;

const PlansScrollItem = styled.div`
  & {
    position: sticky;
    bottom: -20px;
    margin-top: -20px;
    height: 103px;
    background-image: linear-gradient(to bottom, transparent, var(--white) 78%);

    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const TreeCard = styled.div`
  & {
    display: grid;
    column-gap: 16px;
    margin: 26px 0 6px 0;
    padding: 3px 18px 3px 0;
    grid-template-columns: max-content 1fr;
    border-radius: 4px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    align-items: center;

    @media ${({ theme }) => theme.device.web} {
      margin: 50px 0;
    }
  }
`;

const TreeImage = styled(Image)`
  & {
    width: 63px;
  }
`;

const TreeDescription = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const FormFields = styled.div`
  & {
    margin: 46px 0;
    display: grid;
    row-gap: 12px;
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    @media ${({ theme }) => theme.device.web} {
      margin: 50px 0;
    }
  }
`;

const SubTitle = styled.div`
  & {
    color: var(--dark-blue-grey);
    line-height: 1.6;
    font-size: 15px;
    font-family: 'Airbnb Cereal App Bold' !important;
  }
`;

const FormTitleRow = styled.div`
  & {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr max-content max-content max-content;
    column-gap: 12px;
    align-items: center;
  }
`;

const LongTextField = styled(TextField)`
  & {
    grid-column: span 2;
  }
`;

const Disclaimer = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
    > a {
      color: var(--green);
      text-decoration: none;
    }
  }
`;

const SubmitButton = styled(Button)`
  && {
    display: none;

    @media ${({ theme }) => theme.device.web} {
      display: initial;
      margin-top: 50px;
      padding: 18px 0;
      width: 200px;
      border-radius: 4px;
      background-color: var(--dark-blue-grey);
      border: none;
      line-height: 1.2;
      font-size: 15px;
      color: var(--white);

      :hover {
        background-color: var(--rhino);
      }

      svg {
        color: var(--white);
      }
    }
  }
`;

const ReceiptCard = styled.div`
  & {
    display: none;

    @media ${({ theme }) => theme.device.web} {
      display: initial;
      position: sticky;
      top: 80px;
      padding: 24px 20px;
      border-radius: 4px;
      border: solid 1px var(--very-light-pink);
      width: 280px;
      height: max-content;
    }
  }
`;

const ReceiptTitle = styled.div`
  & {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 6px;
    align-items: center;
    font-size: 15px;
    color: var(--dark-blue-grey);
  }
`;

const Divider = styled.div`
  & {
    margin: 24px 0;
    height: 1px;
    background: var(--very-light-pink);
  }
`;

const ReceiptItem = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    > div {
      font-family: 'Airbnb Cereal App Book' !important;
    }
  }
`;

const ReceiptTotal = styled.div`
  & {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    font-size: 15px;
    line-height: 1.6;
    color: var(--dark-blue-grey);
  }
`;

const ReceiptTotalPrice = styled.div`
  & {
    ${({ discount }) => discount
      && `
      text-decoration: line-through;    
      font-family: 'Airbnb Cereal App Light' !important;
      color: var(--pinkish-grey);
    `}
  }
`;

const ReceiptDiscountedPrice = styled.div`
  & {
    margin-left: 6px;
    color: var(--green);
  }
`;

const FreeCancellationTitle = styled.div`
  & {
    font-size: 15px;
    line-height: 1.6;
    color: var(--dark-blue-grey);
  }
`;

const FreeCancellationDescription = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;

    a {
      color: var(--green);
      text-decoration: none;
    }
  }
`;

const Footer = styled.div`
  & {
    position: sticky;
    bottom: 0;
    height: 65px;
    box-shadow: 0 -11px 24px 0 var(--dark-blue-grey-13);
    display: grid;
    grid-template-areas:
      'back title title submit'
      'back price discounted-price submit';
    column-gap: 10px;
    grid-template-columns: max-content max-content 1fr max-content;
    background: var(--white);
    z-index: 10000;

    @media ${({ theme }) => theme.device.web} {
      display: none;
    }
  }
`;

const BackLink = styled(Link)`
  & {
    grid-area: back;
    background: none;
    border: none;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    margin-left: 10px;
    align-self: center;
  }
`;

const FooterTitle = styled.div`
  & {
    grid-area: title;
    align-self: end;
    font-size: 13px;
    line-height: 1.54;
    color: var(--slate);
  }
`;

const FooterPaidPrice = styled.div`
  & {
    grid-area: price;
    font-size: 18px;
    line-height: 1.33;
    color: var(--dark-blue-grey);
  }
`;

const FooterPreSalePrice = styled.div`
  & {
    grid-area: price;
    font-size: 15px;
    line-height: 1.6;
    color: var(--pinkish-grey);
    text-decoration: line-through;
    font-family: 'Airbnb Cereal App Light' !important;
  }
`;

const FooterDiscountedPrice = styled.div`
  & {
    grid-area: discounted-price;
    font-size: 18px;
    line-height: 1.33;
    color: var(--green);
  }
`;

const FooterSubmitButton = styled(Button)`
  && {
    grid-area: submit;
    width: 148px;
    background-color: var(--dark-blue-grey);
    border: none;
    border-radius: 0;
    line-height: 1.2;
    font-size: 15px;
    color: var(--white);

    :hover {
      background-color: var(--rhino);
    }

    svg {
      color: var(--white);
    }
  }
`;

const CouponCard = styled.div`
  & {
    height: 64px;
    margin: 14px 0 44px 0;
    padding-left: 16px;
    border-radius: 4px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-size: 13px;
    line-height: 1.54;
    color: ${({ discount }) => (discount ? 'var(--green)' : 'var(--slate)')};

    > svg {
      align-self: flex-start;
    }
  }
`;

const DiscountDescription = styled.div`
  & {
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const CouponButton = styled(Button)`
  && {
    width: 106px;
    height: 64px;
    background-color: var(--dark-blue-grey);
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    color: var(--white);
    border: none;
    border-radius: 0;

    :hover {
      background-color: var(--rhino);
    }
  }
`;
