import React from 'react';
import * as PropTypes from 'prop-types';

/* eslint-disable max-len */
export const IconSelected = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#1EAB31"
        stroke="#1EAB31"
        strokeWidth="2"
        d="M2.414 1L29 27.586V4a3 3 0 0 0-3-3H2.414z" />
      <path
        fill="#FFF"
        d="M19.313 11.017l5.79-5.79a.562.562 0 1 1 .795.796L19.71 12.21a.562.562 0 0 1-.795 0l-2.813-2.812a.562.562 0 1 1 .796-.796l2.414 2.415z" />
    </g>
  </svg>
);

IconSelected.propTypes = {
  className: PropTypes.string,
};

IconSelected.defaultProps = {
  className: undefined,
};
