import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

export const TutorialDialog = ({ images, isOpen, onFinish }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const handleImageClick = () => {
    if (imageIndex === images.length - 1) {
      onFinish();
    } else {
      setImageIndex((val) => val + 1);
    }
  };

  return (
    <StyledDialog open={isOpen}>
      <DialogContent>
        <Image
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          src={images[imageIndex]}
          alt="tutorial"
          onClick={handleImageClick} />
      </DialogContent>
    </StyledDialog>
  );
};

TutorialDialog.propTypes = {
  images: PropTypes.arrayOf({
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool,
  onFinish: PropTypes.func,
};

TutorialDialog.defaultProps = {
  isOpen: false,
  onFinish: undefined,
};

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root.MuiDialog-paper {
    background-color: transparent;
    box-shadow: none;
  }
`;

const DialogContent = styled.div`
  & {
    max-width: 90vw;
    max-height: 90vh;
    box-sizing: border-box;
    display: flex;
  }
`;

const Image = styled.img`
  & {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;
