import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { IconStar } from './IconStar';
import { IconEffort } from './IconEffort';
import { IconDifficulty } from './IconDifficulty';
import { IconWalk } from './IconWalk';

export const PlanSummary = ({
  designer: {
    image, name, rating, ratingCount,
  },
  date,
  title,
  description,
  planAvaregePrice,
  planCurrency,
  difficulty,
  walking,
  // designerUrl,
  className,
  children,
}) => (
  <Summary className={className}>
    <Profile>
      <div id="designer-row">
        {date ? (
          <CardDate>
            <Month>{date.toLocaleString('en', { month: 'short' }).toUpperCase()}</Month>
            <Day>{date.toLocaleString('en', { day: 'numeric' })}</Day>
          </CardDate>
        ) : (
          <div />
        )}
        {/* <DesignerLink as={designerUrl ? Link : undefined} to={designerUrl}> */}
        <DesignerLink as={undefined} to={undefined}>
          <DesignerImage alt="designer" src={image} />
          <DesignerName>{name}</DesignerName>
          <DesignerStar>
            <IconStar />
            <Rate>{rating.toFixed(1)}</Rate>
            {ratingCount !== null && <RateCount>{`(${ratingCount})`}</RateCount>}
          </DesignerStar>
        </DesignerLink>
      </div>
    </Profile>
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CardSummary>
        <CardGrid>
          <SummaryImage as={IconEffort} />
          <SummaryTitle>
            <Trans i18nKey="planDetail.effort" />
          </SummaryTitle>
          <SummaryValue>{planAvaregePrice ? `${planCurrency}${planAvaregePrice}` : '-'}</SummaryValue>
        </CardGrid>
        <CardGrid>
          <SummaryImage as={IconDifficulty} />
          <SummaryTitle>
            <Trans i18nKey="planDetail.difficulty" />
          </SummaryTitle>
          <SummaryValue>{difficulty || '-'}</SummaryValue>
        </CardGrid>
        <CardGrid>
          <SummaryImage as={IconWalk} />
          <SummaryTitle>
            <Trans i18nKey="planDetail.walking" />
          </SummaryTitle>
          <SummaryValue>{walking || '-'}</SummaryValue>
        </CardGrid>
      </CardSummary>
      {children}
    </Content>
  </Summary>
);

PlanSummary.propTypes = {
  designer: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    rating: PropTypes.number,
    ratingCount: PropTypes.number,
  }),
  date: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  description: PropTypes.string,
  planCurrency: PropTypes.string,
  planAvaregePrice: PropTypes.string,
  difficulty: PropTypes.string,
  walking: PropTypes.string,
  // designerUrl: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

PlanSummary.defaultProps = {
  designer: {
    image: '',
    name: '',
    rating: 0,
    ratingCount: 0,
  },
  date: undefined,
  title: '',
  description: '',
  planCurrency: '',
  planAvaregePrice: '',
  difficulty: '',
  walking: '',
  // designerUrl: '',
  children: null,
  className: '',
};

const Summary = styled.div`
  & {
    grid-area: summary;
    background-color: var(--white);
    @media ${({ theme }) => theme.device.web} {
      position: sticky;
      top: 80px;
      width: 320px;
      border-radius: 4px;
      box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
      height: max-content;
      margin-bottom: 30px;
    }
  }
`;

const Profile = styled.div`
  & {
    position: absolute;
    margin: 0 -24px;
    width: calc(100% - 48px);
    top: 0;
    height: 150px;
    padding: 24px;
    background-image: linear-gradient(to bottom, var(--black-0) -58%, var(--black-76));
    display: flex;

    > div {
      height: max-content;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }

    @media ${({ theme }) => theme.device.web} {
      margin: 0;
      position: initial;
      background: none;
      height: initial;
    }
  }
`;

const DesignerLink = styled.div`
  & {
    display: grid;
    grid-template-areas:
      'image name'
      'image star';
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    column-gap: 8px;
    text-decoration: none;
  }
`;

const DesignerImage = styled.img`
  & {
    grid-area: image;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: solid 1px var(--white);
    align-self: center;
    color: var(--white);

    @media ${({ theme }) => theme.device.web} {
      color: var(--dark-blue-grey);
    }
  }
`;

const DesignerName = styled.div`
  & {
    grid-area: name;
    font-size: 15px;
    font-family: 'Airbnb Cereal App Bold' !important;
    color: var(--white);
    align-self: flex-end;

    @media ${({ theme }) => theme.device.web} {
      color: var(--dark-blue-grey);
    }
  }
`;

const DesignerStar = styled.div`
  & {
    grid-area: star;
    font-size: 12px;
    font-family: 'Airbnb Cereal App Light' !important;
    color: var(--white);
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 4px;

    @media ${({ theme }) => theme.device.web} {
      color: var(--dark-blue-grey);
    }
  }
`;

const Rate = styled.div`
  & {
    font-family: 'Airbnb Cereal App Bold' !important;
  }
`;

const RateCount = styled.div`
  & {
    font-family: 'Airbnb Cereal App Light' !important;
  }
`;

const CardDate = styled.div`
  & {
    grid-area: date;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 4px;
    background-color: var(--white);

    @media ${({ theme }) => theme.device.web} {
      width: 72px;
      height: 72px;
      box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    }
  }
`;

const Month = styled.div`
  & {
    font-size: 13px;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;

    @media ${({ theme }) => theme.device.web} {
      font-size: 22px;
    }
  }
`;

const Day = styled.div`
  & {
    font-size: 15px;
    color: var(--dark-blue-grey);

    @media ${({ theme }) => theme.device.web} {
      font-size: 24px;
    }
  }
`;

const Description = styled.div`
  & {
    font-size: 13px;
    line-height: 1.85;
    color: var(--slate);
    font-family: 'Airbnb Cereal App Book' !important;
  }
`;

const CardSummary = styled.div`
  & {
    border-radius: 4px;
    box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
    background-color: var(--white);
    padding: 22px 16px;
    margin: 30px 0 10px 0;
    display: flex;

    @media ${({ theme }) => theme.device.web} {
      box-shadow: none;
      padding: 0;
      margin: 30px 0;
    }
  }
`;

const CardGrid = styled.div`
  & {
    flex: 1;
    display: grid;
    grid-template-areas:
      'image title'
      'image value';
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    column-gap: 8px;
    row-gap: 2px;
  }
`;

const SummaryImage = styled.div`
  & {
    grid-area: image;
    align-self: center;
  }
`;

const SummaryTitle = styled.div`
  & {
    grid-area: title;
    font-size: 12px;
    color: var(--blue-grey);
    font-family: 'Airbnb Cereal App Book' !important;
    white-space: nowrap;
  }
`;

const SummaryValue = styled.div`
  & {
    grid-area: value;
    font-size: 12px;
    color: var(--dark-blue-grey);
    font-family: 'Airbnb Cereal App Bold' !important;
  }
`;

const Content = styled.div`
  & {
    padding: 28px 0;

    @media ${({ theme }) => theme.device.web} {
      padding: 14px 24px 28px 24px;
    }
  }
`;

const Title = styled.h1`
  & {
    margin: 0 0 4px 0;
    font-size: 15px;
    line-height: 1.45;
    color: var(--dark-blue-grey);
  }
`;
