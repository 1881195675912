import {
  WelcomePage, PlanPage, DesignerPage, PlanChangePage, PaymentPage, ExpiredPage, ErrorPage, CompletedPage,
} from 'pages';

/* eslint-disable max-len */

export const redirects = [
  {
    from: '/',
    to: '/not-found',
  },
];

export const getRoutes = (status) => {
  switch (status) {
  case 1:
  case 2:
    return [
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/pay',
        page: PaymentPage,
      },
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/:date([0-9]{4}-[0-1][0-9]-[0-3][0-9])/detail',
        page: PlanPage,
      },
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/:date([0-9]{4}-[0-1][0-9]-[0-3][0-9])/change',
        page: PlanChangePage,
      },
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/:planId(\\d{1,10})/designer',
        page: DesignerPage,
      },
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        page: WelcomePage,
      },
    ];
  case 3:
  case 10:
    return [
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        Page: CompletedPage,
      },
    ]; // success;
  case 8:
  case 9:
    return [
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        Page: ExpiredPage,
      },
    ];
  default:
    return [
      {
        path: '/:guid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        Page: ErrorPage,
      },
    ]; // cancel
  }
};

export const getPlanUrl = ({ guid, date }) => `/${guid}/${date.getFullYear()}-${date.toLocaleString('en', {
  month: '2-digit',
})}-${date.toLocaleString('en', { day: '2-digit' })}/detail`;
export const getDesignerUrl = ({ guid, planId }) => `/${guid}/${planId}/designer`;
export const getPlanChangeUrl = ({ guid, date }) => `/${guid}/${date.getFullYear()}-${date.toLocaleString('en', {
  month: '2-digit',
})}-${date.toLocaleString('en', { day: '2-digit' })}/change`;
export const getPaymentUrl = ({ guid }) => `/${guid}/pay`;
export const getWelcomeUrl = ({ guid }) => `/${guid}`;
